<template>
  <v-app style="background-color: #f5faff">
    <onebox_toolbar
      :quicksearch="true"
      :parentfolder="parentfolder"
      @inputdata="filteredItems"
      @loadfile="loadfolder()"
      @callparentfolder="getSourceSystem()"
      @callstorage="loadstorage()"
    ></onebox_toolbar>
    <v-content>
      <v-divider></v-divider>
      <v-overlay :value="processloader" absolute color="grey lighten-4" dark>
        <v-progress-circular indeterminate size="64" color="blue"></v-progress-circular>
        <br />
        <br />
        <span style="color: #2196F3">&nbsp; &nbsp; loading</span>
      </v-overlay>
      <v-list nav color="#f5faff">
        <v-list-item class="text-left">
          <v-btn
            @mouseover="show_back = true"
            @mouseleave="show_back = false"
            fab
            small
            color="#1565C0"
            @click="backtopreventfolder(), (show_back = false)"
            class="ma-2"
          >
            <v-icon color="white">keyboard_backspace</v-icon>
            <v-badge
              v-if="$t('default') === 'th'"
              style="z-index:10000;bottom:-45px;left:-35px;"
              :value="show_back"
              :content="$t('balloon.back')"
              transition="slide-y-transition"
            ></v-badge>
            <v-badge
              v-else
              style="z-index:10000;bottom:-45px;left:-27px;"
              :value="show_back"
              :content="$t('balloon.back')"
              transition="slide-y-transition"
            ></v-badge>
          </v-btn>
          <v-list-item-avatar class="text-center">
            <v-avatar color="primary" size="35">
              <v-icon dark size="20">{{ defaulticon }}</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span
                style="color: #1565C0;  font-size: 18px; font-weight: 600;"
              >{{ Labeldata }} ({{ $route.query.sourcesystem }})</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card class="elevation-0" color="#f5faff" id="thiscontainer">
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-layout row wrap justify-center>
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <v-expansion-panels v-model="panel" multiple outlined>
                  <v-expansion-panel class="pa-1">
                    <v-expansion-panel-header>
                      <span
                        style="font-size: 18px; font-weight: 600;"
                      >{{ $t('dcmPage.conditionFile')}}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-1">
                      <v-form>
                        <v-layout row wrap fill-height justify-center>
                          <v-flex xs12 sm12 md5 lg5 class="pa-1">
                            <v-container fluid>
                              <v-layout row wrap justify-center>
                                <!-- ช่องกรอก เอกสารลงวันที่ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogFromdatedocument"
                                    v-model="modalFromdatedocument"
                                    :return-value.sync="fromdatedocument"
                                    persistent
                                    full-width
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        color="primary"
                                        :label="$t('myoutboxPage.conditionOptional.fromdatedocument')"
                                        v-model="fromdatedocument"
                                        @click:prepend="modalFromdatedocument = true"
                                        prepend-icon="event"
                                        v-on="on"
                                        readonly
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="fromdate_hint"
                                        @click:clear="todatedocument = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <!-- :min="setdate_(todatedocument)" -->
                                    <!-- :max="todatedocument" -->
                                    <v-date-picker
                                      v-model="fromdatedocument"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="changeFromdatedocument()"
                                      :max="callDateNow()"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalFromdatedocument = false"
                                      >{{$t('myoutboxPage.conditionOptional.closefromdatedocument')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>

                                <!-- ช่องกรอก เอกสารลงวันที่ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogTodatedocument"
                                    v-model="modalTodatedocument"
                                    :return-value.sync="todatedocument"
                                    persistent
                                    full-width
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        readonly
                                        color="primary"
                                        :label="$t('myoutboxPage.conditionOptional.todatedocument')"
                                        v-model="todatedocument"
                                        prepend-icon="event"
                                        @click:prepend="modalTodatedocument = true"
                                        v-on="on"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="todate_hint"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                        :disabled="fromdatedocument === '' || fromdatedocument === null"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="todatedocument"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="$refs.dialogTodatedocument.save(todatedocument)"
                                      :min="fromdatedocument"
                                      :max="setdate(fromdatedocument)"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalTodatedocument = false"
                                      >{{$t('myoutboxPage.conditionOptional.closetodatedocument')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <!-- ช่องวันที่ได้รับไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogFromdateupload"
                                    v-model="modalFromdateupload"
                                    :return-value.sync="fromdateupload"
                                    persistent
                                    full-width
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        clearable
                                        color="primary"
                                        :label="$t('myoutboxPage.conditionOptional.fromdateupload')"
                                        v-model="fromdateupload"
                                        @click:prepend="modalFromdateupload = true"
                                        prepend-icon="event"
                                        v-on="on"
                                        readonly
                                        outlined
                                        dense
                                        @click:clear="todateupload = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <!-- :min="setdate_(todateupload)"
                                    :max="todateupload" -->
                                    <v-date-picker
                                      v-model="fromdateupload"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="changeFromdateupload()"
                                      :max="callDateNow()"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalFromdateupload = false"
                                      >{{$t('myoutboxPage.conditionOptional.closefromdateupload')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                                <!-- ช่องวันที่ได้รับไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogTodateupload"
                                    v-model="modalTodateupload"
                                    :return-value.sync="todateupload"
                                    persistent
                                    full-width
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        clearable
                                        readonly
                                        color="primary"
                                        :label="$t('myoutboxPage.conditionOptional.todateupload')"
                                        v-model="todateupload"
                                        prepend-icon="event"
                                        @click:prepend="modalTodateupload = true"
                                        v-on="on"
                                        outlined
                                        dense
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="todateupload"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="$refs.dialogTodateupload.save(todateupload)"
                                      :min="fromdateupload"
                                      :max="setdate(fromdateupload)"
                                      :disabled="fromdateupload === '' || fromdateupload === null"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalTodateupload = false"
                                      >{{$t('myoutboxPage.conditionOptional.closetodateupload')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="receivername"
                                    :label="$t('myoutboxPage.conditionOptional.receivername')"
                                    prepend-icon="account_box"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <!-- ช่องเลือก ประเภเอกสาร -->

                                  <v-select
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="documenttype"
                                    :items="listDoctype"
                                    item-value="document_type_code"
                                    :item-text="$t('default') === 'th' ? 'document_type_th' : 'document_type_eng'"                      
                                    :label="$t('myoutboxPage.conditionOptional.doctype')"
                                    prepend-icon="line_style"
                                  ></v-select>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md12 lg12>
                                  <!-- ช่องกรอก ผู้ส่ง -->
                                  <v-text-field
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="sendername"
                                    :label="$t('myoutboxPage.conditionOptional.sendername')"
                                    prepend-icon="account_circle"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-flex>
                          <div>
                              <div class="pt-6"  
                                @mouseover="show_info_doc = true"
                                @mouseleave="show_info_doc = false"
                                fab
                                x-small
                                  >
                                <v-icon color="primary">mdi-information</v-icon>
                                  <v-badge
                                    v-if="$t('default') === 'th'"
                                    style="z-index:10000;bottom:-30px;left:-35px;"
                                    :value="show_info_doc"
                                    :content="$t('balloon.info_date_doc')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                                  <v-badge
                                    v-else
                                    style="z-index:10000;bottom:-30px;left:-27px;"
                                    :value="show_info_doc"
                                    :content="$t('balloon.info_date_doc')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                     
                              </div>
                              <div class="pt-10"  
                                @mouseover="show_info = true"
                                @mouseleave="show_info = false"
                                fab
                                x-small
                               >
                                <v-icon color="primary">mdi-information</v-icon>
                                  <v-badge
                                    v-if="$t('default') === 'th'"
                                    style="z-index:10000;bottom:-30px;left:-35px;"
                                    :value="show_info"
                                    :content="$t('balloon.info_date_outbox')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                                  <v-badge
                                    v-else
                                    style="z-index:10000;bottom:-30px;left:-27px;"
                                    :value="show_info"
                                    :content="$t('balloon.info_date_outbox')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                     
                              </div>
                            </div>

                          <v-flex xs12 sm12 md1 lg1 class="text-center">
                            <v-divider vertical></v-divider>
                          </v-flex>

                          <v-flex xs12 sm12 md5 lg5 class="pa-1 text-center">
                            <v-container fluid>
                              <v-layout row wrap justify-center>
                                <!-- ช่องกรอกชื่อไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="filename"
                                    :label="$t('myoutboxPage.conditionOptional.filename')"
                                    prepend-icon="insert_drive_file"
                                  ></v-text-field>
                                </v-flex>
                                <!-- ช่องเลือกประเภทไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="fileextension"
                                    :items="listExtensionFile"
                                    item-value="data_type"
                                    item-text="data_type"
                                    :label="$t('myoutboxPage.conditionOptional.fileextension')"
                                    prepend-icon="extension"
                                  ></v-select>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="documentid"
                                    :label="$t('myoutboxPage.conditionOptional.filedocumentid')"
                                    prepend-icon="format_list_numbered"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="documentidto"
                                    :label="$t('myoutboxPage.conditionOptional.todateupload')"
                                    prepend-icon="format_list_numbered"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>

                              <!-- ช่องกรอก SellerTaxID -->
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="sellertaxid"
                                    :label="$t('myoutboxPage.conditionOptional.sellertaxid')"
                                    prepend-icon="mdi-domain"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="sellerbranchid"
                                    :label="$t('myoutboxPage.conditionOptional.sellerbranchid')"
                                    prepend-icon="mdi-format-list-bulleted-square"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                              <!-- ช่องกรอก BuyerTaxID -->
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="buyertaxid"
                                    :label="$t('myoutboxPage.conditionOptional.buyertaxid')"
                                    prepend-icon="mdi-domain"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="buyerbranchid"
                                    :label="$t('myoutboxPage.conditionOptional.buyerbranchid')"
                                    prepend-icon="mdi-format-list-bulleted-square"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-flex>
              <v-flex xs12 lg12 class="text-center">
                <br />
                <v-btn
                  class="ma-1 white--text"
                  color="success"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="searchdocument()"
                >
                  <v-icon left>search</v-icon>
                  &nbsp;
                  {{ $t('myoutboxPage.search')}}
                </v-btn>
                <v-menu :close-on-content-click="closeOnContentClick">
                  <template v-slot:activator="{ on }">
                    <v-btn
                    outlined
                      class="ma-1 white-text"
                      color="success"
                      :disabled="loaddataprogress"
                      :loading="loaddataprogress"
                      v-on="on"
                      >
                      {{$t('dowloads')}}
                      <v-icon>
                        keyboard_arrow_down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-container fluid>
                    <!-- <v-list-item> -->
                      <v-checkbox v-model="typedownloads" class="mt-0" label="Summary File Duplicate"></v-checkbox>
                      <v-checkbox v-model="typeitem" class="mt-0" label="Summary Item (1 ไฟล์ 1 บรรทัด)"></v-checkbox>
                    <!-- </v-list-item> -->
                    </v-container>
                   <v-list-item @click="exportfile(),closeOnContentClick = true" outlined>
                      <v-list-item-title>
                        <v-icon left color="success">mdi-archive-arrow-down</v-icon>
                        {{ "&nbsp;&nbsp;&nbsp;" + $t('myinboxPage.export')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="exportfilecsv(),closeOnContentClick = true" outlined>
                      <v-list-item-title>
                        <v-icon left color="success">insert_drive_file</v-icon>
                        {{ "&nbsp;&nbsp;&nbsp;" + $t('myinboxPage.exportcsv')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="exportfileexcel(),closeOnContentClick = true" outlined>
                      <v-list-item-title>
                        <v-icon left color="success">description</v-icon>
                        {{ "&nbsp;&nbsp;&nbsp;" + $t('myinboxPage.exportexcel')}}
                      </v-list-item-title>
                    </v-list-item>
                </v-list>
                </v-menu>
                <!-- <v-btn
                  class="ma-1"
                  color="primary"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="exportfile()"
                >
                  <v-icon left>mdi-archive-arrow-down</v-icon>
                  {{ "&nbsp;&nbsp;&nbsp;" + $t('myoutboxPage.export')}}
                </v-btn> -->
                <!-- button csv -->
                <!-- <v-btn
                  class="ma-1"
                  color="primary"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="exportfilecsv()"
                >
                  <v-icon left>note</v-icon>
                  {{ "&nbsp;&nbsp;&nbsp;" + $t('myinboxPage.exportcsv')}}
                </v-btn>
                <v-btn
                  class="ma-1"
                  color="primary"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="exportfileexcel()"
                >
                  <v-icon left>note</v-icon>
                  {{ "&nbsp;&nbsp;&nbsp;" + $t('myoutboxPage.exportexcel')}}
                </v-btn> -->
              </v-flex>

              <v-flex xs12 lg12 >
                <v-layout row wrap class="ma-4" >
                  <v-flex xs12 lg2>
                    <v-select
                      class="pr-2"
                      prepend-inner-icon="sort"
                      hide-details
                      solo
                      dense
                      v-model="size"
                      :items="listsize"
                      @change="clickpagination()"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 lg2>
                    <v-select
                      class="pr-2"
                      @change="clickstatus(statusde)"
                      v-model="statusde"
                      hide-details
                      solo
                      dense
                      :items="liststatus"
                    ></v-select>
                  </v-flex>
                   <!-- <v-flex xs12 lg2>
                    <v-btn
                    @click="openreletion=true"
                    color="primary"
                    dark
                    >
                   เพิ่ม relation
                    </v-btn>
                  </v-flex> -->
                  <v-flex lg4 offset-lg4 class="pl-3 pr-2 hidden-md-and-down" v-if="sortby === 'status'">
                    <v-alert
                      dense
                      height="41"
                      v-model="clickSortorderby"
                      color="primary"
                      dark
                      v-show="clickSortorderby"
                      style="margin-bottom: 0%;"
                    >
                      <div>
                        <v-icon color="white" v-if="checkpagination===true">mdi-chevron-up</v-icon>         
                        <v-icon
                          color="white"
                          v-else-if="checkpagination===false"
                        >mdi-chevron-down</v-icon>
                          <span
                          style="color: white"
                          v-if="sortby === 'status'"
                        >{{$t('Filter.status')}}</span>                                     
                        <v-btn
                          width="24"
                          height="24"
                          style="float: right;"
                          text
                          fab
                          x-small
                          color="primary"
                          dark
                          @click="closeclicksort"
                        >
                          <v-icon color="white">mdi-close-circle</v-icon>
                        </v-btn>
                      </div>
                    </v-alert>
                  </v-flex>
                  <v-flex lg4 offset-lg4 class="pl-3 pr-2 hidden-md-and-down" v-else>
                    <!-- <span>{{ totalfile + ' ' + $t('myoutboxPage.items') || 0 + ' ' + $t('myoutboxPage.items')}}</span> -->
                    <v-alert
                      dense
                      height="41"
                      v-model="clickSort"
                      color="light-green accent-4"
                      dark
                      v-show="clickSort"
                      class="ma-0"
                    >
                      <div>
                        <v-icon color="white" v-if="multiSort===true">mdi-chevron-up</v-icon>
                        <v-icon color="white" v-else-if="multiSort===false">mdi-chevron-down</v-icon>
                        <span
                          style="color: white"
                          v-if="sortby === 'name'"
                        >{{$t('Filter.nameonly')}}</span>
                        <span
                          style="color: white"
                          v-else-if="sortby === 'docdate'"
                        >{{$t('Filter.docdate')}}</span>
                        <span
                          style="color: white"
                          v-else-if="sortby === 'docno'"
                        >{{$t('Filter.docno')}}</span>
                        <span
                          style="color: white"
                          v-else-if="sortby === 'outboxdate'"
                        >{{$t('Filter.outboxdate')}}</span>
                        <v-btn
                          width="24"
                          height="24"
                          style="float: right;"
                          text
                          fab
                          x-small
                          color="light-green accent-4"
                          dark
                          @click="checkSort"
                        >
                          <v-icon color="white">mdi-close-circle</v-icon>
                        </v-btn>
                      </div>
                    </v-alert>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs4 lg4>
                    <div v-if="statusmutipledownload === false" class="pt-2">
                      <v-btn
                        color="primary"
                        rounded
                        dark
                        class="ma-1"
                        fab
                        small
                        @click="statusmutipledownload = true"
                      >
                        <v-icon>apps</v-icon>&nbsp;
                        <!-- {{ $t('myoutboxPage.multipledownload')}} -->
                      </v-btn>
                    </div>
                    <div v-else class="pt-2">
                      <v-btn fab small color="primary" class="ma-1" @click="multipledownload()">
                        <v-icon>mdi-download-multiple</v-icon>
                      </v-btn>
                      <!-- <v-btn  fab small color="error" class="ma-1" @click="multipleDelete()">
                        <v-icon color="white">delete</v-icon>
                      </v-btn>-->
                      <v-btn
                        fab
                        small
                        color="error"
                        class="ma-1"
                        @click="statusmutipledownload = false, clearmultipledownload()"
                      >
                        <v-icon>clear</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs8 lg8 class=" text-right">
                    <v-chip color="primary" dark class="ma-2">
                      <v-icon left>view_list</v-icon>
                      {{ totalfile + ' ' + $t('myoutboxPage.items') || 0 + ' ' + $t('myoutboxPage.items')}}
                    </v-chip>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 lg12 class="ma-4" v-if="loaddataprogress === true">
                <div class="text-center">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </div>
              </v-flex>
              <v-flex xs11 lg12 v-else class="ma-4">
                <div v-if="resolutionScreen <= 500">
                  <v-list two-line class="mb-6 pb-6 elevation-1">
                    <v-list-item v-if="outboxdata.length === 0">
                      <v-list-item-content class="text-center">
                        <v-list-item-title v-text="$t('myoutboxPage.Nodata')"></v-list-item-title>
                        <v-list-item-subtitle></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-else
                      v-for="item in outboxdata"
                      :key="item.title"
                      style="cursor: pointer;"
                    >
                      <v-list-item-avatar v-show="statusmutipledownload">
                        <v-checkbox v-model="filemultipledownload" :value="item" v-if="item.trash !=='DELETE'"/>
                      </v-list-item-avatar>
                      <v-list-item-avatar>
                        <v-icon
                          large
                          :color="seticon(item.data_type)[1]"
                          style="font-weight: 100"
                        >{{ seticon(item.data_type)[0]}}</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content @click="previewfile(item)">
                        <v-list-item-title v-text="item.filename"></v-list-item-title>
                        <v-list-item-subtitle v-text="item.document_no"></v-list-item-subtitle>
                        <div>
                          <!-- :color="item.status === 'pending' ? '#ffcc00':item.status === 'reject' ? 'red':item.status === 'unsend' ? '#ff8533': item.status === 'request'? 'primary':item.status === 'request_delete_log'? '#FF0000':item.status === 'pending_receiver_stoage_full'? '#ffcc00':'success'" -->
                          <v-chip
                            x-small
                            dark
                            :color="
                              item.status === 'pending'
                                ? '#ffcc00'
                                : item.status === 'duplicate'
                                ? 'primary'
                                : item.status === 'resend_from_etax'
                                ? '#6fbf72'
                                : item.status === 'sender_delete'
                                ? 'error'
                                : item.status === 'request_delete_log'
                                ? 'error'
                                : item.status === 'reject'
                                ? 'warning'
                                : 'success'
                            "
                          >
                            {{ $t("status_inbox_outbox." + item.status) }}
                            <!-- {{item.status === 'request_delete_log' ? 'ขอลบ' :item.status}} -->
                          </v-chip>
                          <v-icon 
                              v-if="item.trash === 'DELETE'"
                              color="red"
                              small
                              >
                              cancel_presentation
                              </v-icon>
                              <v-icon
                              v-else-if="item.trash === 'T'"
                              color="amber"
                              small
                              >
                              delete_sweep
                            </v-icon>
                        </div>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu>
                          <template v-slot:activator="{ on }">
                            <v-btn class="elevation-0" fab small v-on="on">
                              <v-icon>more_vert</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="opendialog = true , newdata=item" >
                              <v-list-item-icon>
                                <v-icon>mdi-information</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('myoutboxPage.buttonAction.fileDetail')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <!-- <v-list-item @click="openoutbox = true, newdata=item" disabled>
                              <v-list-item-icon>
                                <v-icon>folder</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('myoutboxPage.buttonAction.relatedFile')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item> -->
                            <v-list-item @click="downloadfile(item)" 
                            v-if="item.trash !== 'DELETE' "
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-download-multiple</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('myoutboxPage.buttonAction.download')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                                <v-list-item @click="opendialogdelete = true ,data=item ,statusdelete='N'">
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('checkdelete.deleteonlyme')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                             <v-list-item @click="opendialogdelete = true ,data=item ,statusdelete='Y'">
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('checkdelete.deleteandrequest')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                               <!-- request_delete_log -->
                            <v-list-item
                              v-if="item.status === 'request_delete_log'"
                            >
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="pr-2">
                                <v-btn color="green" dark dense @click="opendialogrequestdelete = true , data=item , statusrequestdelete ='Y'">Delete</v-btn>
                              </v-list-item-content>
                              <v-list-item-content>
                                <v-btn
                                  color="red"
                                  dark
                                  dense
                                  @click="deleterequest(item,'N')"
                                >No</v-btn>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="item.trash !== 'DELETE'  &&  item.trash !== 'T' && item.status !== 'request' && item.status !== 'unsend'">
                              <v-list-item-icon>
                                <v-icon>
                                  sync_alt
                                </v-icon>
                              </v-list-item-icon>
                                <v-list-item-content>
                                  <v-btn @click="buntonRequest(item)" 
                                  color="primary"
                                  dark
                                  >
                                    <v-list-item-title>
                                        Request
                                  </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <!-- 
                    <v-divider inset></v-divider>-->
                  </v-list>
                </div>
                <div v-else>
                  <v-data-table
                    v-model="filemultipledownload"
                    :headers="headers"
                    :items="outboxdata"
                    item-key="file_id"
                    :search="search"
                    class="elevation-1"
                    hide-default-footer
                    :single-select="true"
                    :show-select="statusmutipledownload"
                    :page.sync="page"
                    :items-per-page="size"
                    @page-count="pageCount = $event"
                  >
                    <!-- <template v-slot:header.data_type="{ header }"></template> -->
                    <template v-slot:[`header.data_type`]="{ header }">
                    <span  
                     style="color: #1565C0; font-weight: 600; font-size:15px"
                    @click="sortby='status',clickSortorderby=true ,checkpagination=!checkpagination ,check()"
                    >{{header.text}}</span>
                    </template> 
                    <template v-slot:[`header.filename`]="{ header }">
                      <span
                        @click="sortby='name',clickSort=true,multiSort=!multiSort,sortFile(outboxdata) "
                        style="color: #1565C0; font-weight: 600; font-size:15px"
                      >{{ $t(header.text) }}</span>
                    </template>
                    <template  v-slot:[`header.document_no`]="{ header }">
                      <span
                        @click="sortby='docno',clickSort=true,multiSort=!multiSort,sortFile(outboxdata)  "
                        style="color: #1565C0; font-weight: 600; font-size:15px"
                      >{{ $t(header.text) }}</span>
                    </template>
                    <template  v-slot:[`header.document_date`]="{ header }">
                      <span
                        @click="sortby='docdate', clickSort=true,multiSort=!multiSort,sortFile(outboxdata)  "
                        style="color: #1565C0; font-weight: 600; font-size:15px"
                      >{{ $t(header.text) }}</span>
                    </template>
                    <template  v-slot:[`header.document_type`]="{ header }">
                      <span
                        style="color: #1565C0; font-weight: 600; font-size:15px"
                      >{{ $t(header.text) }}</span>
                    </template>
                    <template  v-slot:[`header.sender_name`]="{ header }">
                      <span
                        style="color: #1565C0; font-weight: 600; font-size:15px"
                      >{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:[`header.receiver_name`]="{ header }">
                      <span
                        style="color: #1565C0; font-weight: 600; font-size:15px"
                      >{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:[`header.cre_dtm`]="{ header }">
                      <span
                        @click="sortby='outboxdate', clickSort=true,multiSort=!multiSort,sortFile(outboxdata)  "
                        style="color: #1565C0; font-weight: 600; font-size:15px"
                      >{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:[`header.file_id`]="{ header }">
                      <span
                        style="color: #1565C0; font-weight: 600; font-size:15px"
                      >{{ $t(header.text) }}</span>
                    </template>

                    <template v-slot:item="props">
                      <tr>
                        <td class="text-center" v-show="statusmutipledownload">
                          <v-checkbox v-model="filemultipledownload" :value="props.item" v-if="props.item.trash !=='DELETE'" />
                        </td>
                        <td width="5%" class="text-center">
                          <v-badge
                              color="red"
                              overlap
                              icon="clear"
                              small
                              v-if="props.item.trash === 'DELETE' "
                            >
                          <v-icon
                            large
                            :color="seticon(props.item.data_type)[1]"
                          >{{ seticon(props.item.data_type)[0]}}</v-icon>
                             </v-badge>
                            <v-badge
                              color="amber"
                              overlap
                              icon="delete_sweep"
                              small
                              v-else-if="props.item.trash === 'T' "
                            >
                          <v-icon
                            large
                            :color="seticon(props.item.data_type)[1]"
                          >{{ seticon(props.item.data_type)[0]}}</v-icon>
                             </v-badge>
                          <v-icon
                          v-else
                            large
                            :color="seticon(props.item.data_type)[1]"
                          >{{ seticon(props.item.data_type)[0]}}</v-icon>
                          <br />
                          <!-- :color="props.item.status === 'pending' ? '#ffcc00':props.item.status === 'reject' ? 'red':props.item.status === 'unsend' ? '#ff8533': props.item.status === 'request'? 'primary':props.item.status === 'request_delete_log'? '#FF0000':props.item.status === 'pending_receiver_stoage_full'? '#ffcc00':'success'" -->
                          <v-chip
                            class="ma-1"
                            x-small
                            dark
                            :color="
                              props.item.status === 'pending'
                                ? '#ffcc00'
                                : props.item.status === 'duplicate'
                                ? 'primary'
                                : props.item.status === 'resend_from_etax'
                                ? '#6fbf72'
                                : props.item.status === 'sender_delete'
                                ? 'error'
                                : props.item.status === 'request_delete_log'
                                ? 'error'
                                : props.item.status === 'reject'
                                ? 'warning'
                                : 'success'
                            "
                          >
                            {{ $t("status_inbox_outbox." + props.item.status) }}
                            <!-- {{props.item.status === 'request_delete_log' ? 'ขอลบ': props.item.status}} -->
                          </v-chip>
                        </td>
                        <td width="5" :title="props.item.filename">{{ props.item.filename | subStr}}</td>
                        <td width="10%">{{ props.item.document_no}}</td>
                        <td
                          width="15%"
                          class="text-center"
                        >{{ setformatdate(props.item.document_date)}}</td>
                        <td width="10%" class="text-center"><v-chip Default color="white">{{ props.item.document_type }}</v-chip></td>
                        <td width="10%" class="text-center"><v-chip Default color="white">{{props.item.sender_name}}</v-chip></td>
                        <td width="10%" class="text-center"><v-chip Default color="white">{{props.item.receiver_name}}</v-chip></td>
                        <td width="15%" class="text-center">{{ formatdatetime(props.item.cre_dtm)}}</td>
                        <td width="5%" class="text-center">
                          <v-menu>
                            <template v-slot:activator="{ on }">
                              <v-btn class="elevation-0" fab small v-on="on">
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="opendialog =true, newdata=props.item" >
                                <v-list-item-icon>
                                  <v-icon>mdi-information</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('myoutboxPage.buttonAction.fileDetail')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <!-- <v-list-item @click="openoutbox = true, newdata=props.item" disabled >
                                <v-list-item-icon>
                                  <v-icon>folder</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('myoutboxPage.buttonAction.relatedFile')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                              <v-list-item @click="downloadfile(props.item)" 
                              v-if="props.item.trash !== 'DELETE' "
                              >
                                <v-list-item-icon>
                                  <v-icon>mdi-download-multiple</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('myoutboxPage.buttonAction.download')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                                      <!-- <v-list-item
                                v-if="props.item.status !== 'request_delete_log' "
                                @click="opendialogdelete = true,data=props.item,statusdelete='N'"
                              >
                                <v-list-item-icon>
                                  <v-icon>delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('checkdelete.deleteonlyme')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                              <v-list-item
                                v-if="props.item.status !== 'request_delete_log' "
                                @click="opendialogdelete = true,data=props.item,statusdelete='Y'"
                                disabled
                              >
                                <v-list-item-icon>
                                  <v-icon>delete_forever</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('checkdelete.deleteandrequest')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                               <v-divider v-if="props.item.status === 'request_delete_log'"></v-divider>
                              <v-list-item v-if="props.item.status === 'request_delete_log'">
                              <v-list-item-icon>
                                <v-icon>
                                  delete
                                </v-icon>
                              </v-list-item-icon>
                                <v-list-item-content>
                                  <v-btn @click="opendialogrequestdelete = true ,data=props.item,statusrequestdelete='Y'" 
                                  color="green"
                                  dark
                                  >
                                    <v-list-item-title>
                                        Delete
                                  </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                                <v-list-item-content class="pl-2">
                                  <v-btn @click="deleterequest(props.item,'N')" 
                                  color="red"
                                  dark
                                  >
                                    <v-list-item-title>
                                        No
                                  </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                            </v-list-item>
                              <v-list-item v-if="props.item.trash !== 'DELETE'  &&  props.item.trash !== 'T' && props.item.status !== 'request' && props.item.status !== 'unsend'">
                              <v-list-item-icon>
                                <v-icon>
                                  sync_alt
                                </v-icon>
                              </v-list-item-icon>
                                <v-list-item-content>
                                  <v-btn @click="buntonRequest(props.item)" 
                                  color="primary"
                                  dark
                                  >
                                    <v-list-item-title>
                                        Request
                                  </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                            </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </template>
                    <!-- <template  v-slot:item="{ items }">
                      <tbody>
                        <tr
                          v-for="(item, index) in items"
                          :key="index"
                          @dblclick="previewfile(item)"
                          style="cursor: pointer;"
                        >
                        <td class="text-center" v-show="statusmutipledownload">
                            <v-checkbox v-model="filemultipledownload" :value="item" />
                          </td>
                          <td class="text-center">
                            <v-icon
                              large
                              :color="seticon(item.data_type)[1]"
                            >{{ seticon(item.data_type)[0]}}</v-icon>
                          </td>
                          <td>{{ item.filename}}</td>
                          <td>{{ item.document_no}}</td>
                          <td class="text-center">{{ setformatdate(item.document_date)}}</td>
                          <td class="text-center">{{ item.document_type }}</td>
                          <td class="text-center">{{item.sender_name}}</td>
                          <td class="text-center">{{ formatdatetime(item.cre_dtm)}}</td>
                          <td class="text-center">
                            <v-btn class="elevation-0" fab small @click="downloadfile(item)">
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>-->
                  </v-data-table>
                </div>
                <div class="text-xs-center">
                  <br />
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                    color="primary"
                    :total-visible="10"
                    @input="clickpagination()"
                  ></v-pagination>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card class="elevation-0">
        <div style="background-color: #f5faff">
          <previewfile
            :show="openpreviewfile"
            :filedata="currentfile"
            @closepreviewfile="openpreviewfile = false"
            @openpreviewfile="openpreviewfile = true"
          ></previewfile>
          <outboxdetail :show="openoutbox" @closedialog="openoutbox= false" :fileoutbox="newdata" :filedata="outboxdata"/>
          <dialogdetailoutbox
            :show="opendialog"
            @closedialog="opendialog= false"
            :fileoutbox="newdata"
          />
          <insertrelation
            :show="openreletion"
            :outboxdata="outboxdata"
            @closedialog="openreletion= false"
            @loaddata="getSourceSystem()"
          ></insertrelation>
          <dialogcheckrequestdelete
            :show="opendialogrequestdelete"
            @closedialog="opendialogrequestdelete=false"
            @reload="getSourceSystem()"
            :data="data"
            :status="statusrequestdelete"
          ></dialogcheckrequestdelete>
          <dialogcheckdelete
            :show="opendialogdelete"
            @closedialog="opendialogdelete=false"
            @reload="getSourceSystem()"
            :data="data"
            :status="statusdelete"
          ></dialogcheckdelete>
          <dialogsnackbermultidowload
            :show="opensnackbarmultidownload"
            @closedialog="opensnackbarmultidownload=false"
            :percent="percent"
            :date='datetime'
            :namedowload="namedowload"
            :btsdownload="btsdownload"
            :count_loadingfile="count_loadingfile"
            :count_allfile="count_allfile"
            :statusloading="statusloading"
            :showtotalfileonly="showtotalfileonly"
          ></dialogsnackbermultidowload>
                  <!-- snackbardowloadfile 1 file -->
   <div class="text-center ma-2">
    <v-snackbar
      v-model="snackbardowloadonefile"
      bottom
      color="white"
    ><v-list color="white" style="padding-top: 0px;" >
    <v-toolbar color="primary" dense short dark flat fab>{{$t('myinboxPage.dowload.dowloading')}}<v-spacer></v-spacer><v-btn
        color="red"
        text
        @click="snackbardowloadonefile = false"
        fab
        x-small
      ><v-icon>mdi-window-close</v-icon>
      </v-btn></v-toolbar><v-list-item
      dense 
      style="height:40px; width:400px;"    
     ><v-list-item-content >
            <v-list-item-title > {{namedowload}}</v-list-item-title>
          </v-list-item-content>
          <v-progress-circular        
          :rotate="90"
          :size="35"
          :width="3"
          :value= percentCompleted
          color="teal"
          style="margin-left:25px"
        >&nbsp; {{ percentCompleted }}
        </v-progress-circular>
          </v-list-item></v-list>       
    </v-snackbar>
     </div>

          <!-- snackbardowloadfileทั้งหมด -->
          <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowloadonetotal" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar color="primary" dense short dark flat fab>
                  {{$t('myinboxPage.dowload.dowloading')}}
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="snackbardowloadonetotal = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                  <v-list-item-content>
                    <v-list-item-title style="color:black">{{ExportOutboxFiles}}</v-list-item-title>
                  </v-list-item-content>
                  <v-progress-circular
                    :rotate="90"
                    :size="35"
                    :width="3"
                    :value="percentCompleted"
                    color="teal"
                    style="margin-left:25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular>
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div>

          <!-- snackbardowloadfilecsv ทั้งหมด -->
          <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowloadonetotalcsv" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar color="primary" dense short dark flat fab>
                  {{$t('myinboxPage.dowload.dowloading')}}
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="snackbardowloadonetotalcsv = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                  <v-list-item-content>
                    <v-list-item-title style="color:black">{{ExportOutboxFilescsv}}</v-list-item-title>
                  </v-list-item-content>
                  <v-progress-circular
                    :rotate="90"
                    :size="35"
                    :width="3"
                    :value="percentCompleted"
                    color="teal"
                    style="margin-left:25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular>
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div>

             <!-- snackbardowloadfileexcel ทั้งหมด -->
          <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowloadonetotalexcel" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar color="primary" dense short dark flat fab>
                  {{$t('myinboxPage.dowload.dowloading')}}
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="snackbardowloadonetotalexcel = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                  <v-list-item-content>
                    <v-list-item-title style="color:black">{{ExportOutboxFilesexcel}}</v-list-item-title>
                  </v-list-item-content>
                  <v-progress-circular
                    :rotate="90"
                    :size="35"
                    :width="3"
                    :value="percentCompleted"
                    color="teal"
                    style="margin-left:25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular>
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div>
        </div>
      </v-card>
    </v-content>
  </v-app>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import { saveAs } from "file-saver";
import onebox_toolbar from "@/components/layout/layout-business-toolbar";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import format from "date-fns/format";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
// const onebox_toolbar = () => import("../components/layout/layout-toolbar");
const previewfile = () => import("@/components/optional/dialog-preview");
const dialogdetailoutbox = () =>
  import("@/components/optional/dialog-detailoutbox");
const outboxdetail = () => import("@/components/optional/dialog-outboxdetail");
const insertrelation = () => import("@/components/optional/dialog-insertrelationoutbox");
const dialogcheckdelete = () => import("@/components/optional/dialog-checkdeleteoutbox");
const dialogcheckrequestdelete = () => import("@/components/optional/dialog-checkrequestdeleteoutbox");
const dialogsnackbermultidowload =() => import("@/components/optional/dialog-snackbarmultidowmload");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
export default {
  name: "myoutbox_sourcesystem",
  components: { onebox_toolbar, previewfile, outboxdetail, dialogdetailoutbox ,insertrelation ,dialogcheckdelete ,dialogcheckrequestdelete,dialogsnackbermultidowload},
  data: function() {
    return {
      showtotalfileonly: false,
      show_info_doc:false,
      show_info:false,
      typedownloads:false,
      typeitem:false,
      statusloading:"",
      count_loadingfile:0,
      count_allfile:0,
      btsdownload:false,
      opensnackbarmultidownload:false,
      datetime: "",
      percent: 0,
      closeOnContentClick:false,
      opendialogrequestdelete:false,
      opendialogdelete:false,
      data:'',
      statusdelete:'',
      statusrequestdelete:'',
      sortStatus:'',
      namedowload:"",
      clickSortorderby: false,
      checkpagination: false,
      openreletion:false,
      percentCompleted: 0,
      snackbardowloadonefile:false,
      snackbardowloadonetotal: false,
      snackbardowloadonetotalcsv: false,
      snackbardowloadonetotalexcel: false,
      ExportOutboxFiles: "ExportOutboxFiles",
      ExportOutboxFilescsv: "ExportOutboxFilescsv",
      ExportOutboxFilesexcel: "ExportOutboxFilesexcel",
      // statusde: "Active",
      // liststatus: ["Active", "Pending","Duplicate","request for removal sent"],
      // liststatus: ["All", "Active", "Pending", "Reject","Request","Unsend"],
      statusde: "All",
      sortStatus: "",
      liststatus: ["All","Active", "Pending","Duplicate","Resend From Etax","Sender Delete","Request For Removal Sent","Reject"],
      newdata: [],
      openoutbox: false,
      opendialog: false,
      search: "",
      filemultipledownload: [],
      statusmutipledownload: false,
      listsize: [20, 50, 100],
      defaulticon: "",
      listExtensionFile: [],
      listDoctype: [],
      currentfile: {},
      openpreviewfile: false,
      nodata: this.$t("myoutboxPage.Nodata"),
      panel: false,
      filename: "",
      fileextension: "",
      documentid: "",
      documentidto: "",
      documenttype: "",
      sendername: "",
      systemname: "",
      sellertaxid: "",
      sellerbranchid: "",
      receivername: "",
      buyertaxid: "",
      buyerbranchid: "",
      fromdateupload: "",
      modalFromdateupload: false,
      todateupload: "",
      modalTodateupload: false,
      fromdatedocument: "",
      modalFromdatedocument: false,
      todatedocument: "",
      modalTodatedocument: false,
      loaddataprogress: false,
      outboxdata: [],
      headers: [
        {
          text: "#",
          align: "left",
          sortable: false,
          value: "data_type",
          width: "5%"
        },
        {
          text: "tablefile.filenameonly",
          align: "left",
          value: "filename",
          sortable: false,
          width: "10%"
        },
        {
          text: "tablefile.docno",
          align: "left",
          value: "document_no",
          sortable: false,
          width: "10%"
        },
        {
          text: "tablefile.docdate",
          align: "left",
          value: "document_date",
          sortable: false,
          width: "15%"
        },
        {
          text: "tablefile.doctype",
          value: "document_type",
          align: "left",
          sortable: false,
          width: "10%"
        },
        {
          text: "tablefile.sendername_",
          value: "sender_name",
          align: "left",
          sortable: false,
          width: "10%"
        },
        {
          text: "tablefile.receivername_",
          value: "receiver_name",
          align: "left",
          sortable: false,
          width: "10%"
        },
        {
          text: "tablefile.outboxdate",
          value: "cre_dtm",
          align: "left",
          sortable: false,
          width: "15%"
        },
        {
          text: "tablefile.option",
          value: "file_id",
          align: "left",
          sortable: false,
          width: "5%"
        }
      ],
      fromdate: "",
      fromdate_hint: "",
      todate_hint: "",
      size: 20,
      page: 1,
      totalfile: 0,
      sortby: "",
      clickSort: false,
      folderSort: [],
      fileSort: [],
      sortdata: [],
      buff: [],
      multiSort: false,
      show_back: false,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "service"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax"
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.totalfile;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      }
    },
    parentfolder() {
      return this.dataAccountActive["directory_id"];
    },
    Labeldata: {
      get() {
        let companyname = "";
        if (this.$route.query.type === 2) {
          this.defaulticon = "business";
          if (this.$t("default") === "th") {
            if (
              this.$route.query.nameth === undefined ||
              this.$route.query.nameth === null ||
              this.$route.query.nameth === ""
            ) {
              companyname =
                this.dataAccountActive["business_info"]["first_name_th"] || "-";
            } else {
              companyname = this.$route.query.nameth;
            }
          } else {
            if (
              typeof this.$route.query.nameen === undefined ||
              this.$route.query.nameen === null ||
              this.$route.query.nameen === ""
            ) {
              companyname =
                this.dataAccountActive["business_info"]["first_name_eng"] ||
                "-";
              companyname = this.$route.query.nameen;
            } else {
              companyname = this.$route.query.nameen;
            }
          }

          return this.$t("toolbar.myoutbox.Businessname") + " " + companyname;
          //  this.getSourceSystem()
        } else {
          this.defaulticon = "face";
          //  this.getSourceSystem()
          return this.$t("toolbar.myoutbox.Personalname");
        }
      }
    },
    showdatafilter() {
      // console.log(search);
      return this.outboxdata.filter(item => {
        return (
          item.filename.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    }
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 30) return string.substring(0, 30) + "...";
        else return string;
      }
    }
  },
  methods: {
    setdate_(date_) {
      if (date_) {
        console.log("date_", date_);
        var date = new Date(date_);
        // var y = date.getFullYear()
        // var m = date.getMonth() + 1
        // var d = date.getDay() + 90
        date.setDate(date.getDate() - 90);
        console.log(date);
        return new Date(date).toISOString().substr(0, 10);
      }
      return date_;
    },
    setdate(date_) {
      if (date_) {
        console.log("date_", date_);
        var date = new Date(date_);
        var dateNow = new Date();
        // var y = date.getFullYear()
        // var m = date.getMonth() + 1
        // var d = date.getDay() + 90
        date.setDate(date.getDate() + 90);
        console.log(date);
        // return new Date(date).toISOString().substr(0, 10);
        if (date > dateNow) {
          return new Date(dateNow).toISOString().substr(0, 10);
        } else {
          console.log(date);
          return new Date(date).toISOString().substr(0, 10);
        }
      }
      return date_;
      // if(date_ === ''){
      //   var date = new Date(),
      //     y = date.getFullYear(),
      //     m = date.getMonth(),
      //     d = date.getDate() + 90;
      //   console.log("d", d);
      //   var Day = new Date(y, m, d).toISOString().substr(0, 10);
      //   console.log(Day);
      //   return Day;
      // }
    },
    //  deleteinbox(item_ , status){
    //   console.log(item_);
    //   console.log(status);
    //   let payload = {
    //     account_id:this.dataAccountId,
    //     inbox_id:[item_.inbox_id],
    //     send_request: status,
    //     delete_log:"outbox",
    //     user_id:this.dataUsername
    //   }
    //   console.log("payload",payload);
    //     this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_log_inbox",
    //       payload
    //     )
    //     .then((response) => {
    //       console.log(response);
    //       if (response.data.status === "OK") {
    //         if(status === 'N'){
    //            Toast.fire({
    //           icon: "success",
    //           title: "Delete Success",
    //         });
    //         this.getSourceSystem();
    //         }else if(status === 'Y'){
    //             Toast.fire({
    //           icon: "success",
    //           title: "Delete & Request Success",
    //         });
    //         this.getSourceSystem();
    //         }           
    //       }
    //     });
    // },
   async deleterequest(_item ,status){
      console.log(_item,status);
      let payload = {
        account_id:this.dataAccountId,
        inbox_id:[_item.inbox_id],
        request_answer:status,
        delete_log:"outbox",
        user_id:this.dataUsername
      }
      let auth = await gbfGenerate.generateToken();
      console.log("payload",payload);
       await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_log_inbox_requset",
          payload,{headers: { Authorization: auth.code },}
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            if(status === 'Y'){
               Toast.fire({
              icon: "success",
              title: "Delete Success",
            });
            this.getSourceSystem();
            }else if(status === 'N'){
                Toast.fire({
              icon: "success",
              title: "Success",
            });
            this.getSourceSystem();
            }           
          }
        });
    },
    async buntonRequest(item){
      let payload= {
         inbox_id:[item.inbox_id],
         status:"request",
         user_id:"",
         account_id:this.dataAccountId
      }
      console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
     await this.axios
      .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/set_unsend_inbox",
            payload,{headers: { Authorization: auth.code },}
      )
      .then((response) =>{
        console.log(response);
        if(response.data.status === 'OK'){
             Toast.fire({
                icon: "success",
                title: "Success",
              });
              this.getSourceSystem()
        }
      })

      
    },
    closeclicksort(){
      this.clickSortorderby = false;
      this.checkpagination = false;
      this.check();
    },
    check(){
      if(this.checkpagination === true){
        this.checksortorderby();
      }else{
        this.getSourceSystem();
      }
    },
    checksortorderby(){
        let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.$router.push("/myoutbox-system");
          } else {
            this.getDataExtensionFile(this.$route.query.biz, "2");
            this.getDataDocType(this.$route.query.biz, "2");
            this.sortorderby(this.$route.query.biz, "2");
          }
        } else {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.getDataExtensionFile(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
            this.getDataDocType(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
            this.sortorderby(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
          } else {
            this.getDataExtensionFile(this.$route.query.biz, "2");
            this.getDataDocType(this.$route.query.biz, "2");
            this.sortorderby(this.$route.query.biz, "2");
          }
        }
      } else {
        this.getDataDocType(this.dataAccountId, "1");
        this.sortorderby(this.dataAccountId, "1");
        this.getDataExtensionFile(this.dataAccountId, "1");
        // if (this.dataAccountActive["type"] === "Business") {
        //   this.loadSourceSystem(_accountid, _type)
        // } else {
        //   this.loadSourceSystem(_accountid, _type)
        // }
      }
    },
    async sortorderby(_accountid, _type) {
      this.loaddataprogress = true;
      let offset = this.size * (this.page - 1);
      let payload = {
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        limit: this.size.toString(),
        offset: offset.toString(),
        sender_id: [_accountid],
        sender_type: _type,
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name: this.sendername  === null ? "" : this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        order_by: "test",
        sort_by: this.sort_by,
        direction: this.direction,
        inbox_page: this.dataAccountActive.type === 'Citizen' ? "1" : "2"
      };
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v3/get_file_outbox",
          payload,{headers: { Authorization: auth.code },}
        )
        .then(res => {
          if (res.data.status === "OK") {
            console.log("resoutboxbusiness-----",res);
            this.outboxdata = [];
            if ("total_file" in res["data"]["result"]) {
              this.totalfile = res.data.result["total_file"] || 0;
              for(let i=0 ; i < res.data.result.data.length ; i++){
              //   // if(res.data.result.data[i].relation_status === 0 || res.data.result.data[i].relation_status === 2){
                  this.outboxdata.push(res.data.result.data[i])
              //   // }
              }
              console.log("this.outboxdataorderby",this.outboxdata);
              // this.outboxdata = res.data.result["data"];
              this.buff = res.data.result["data"];
            } else {
              this.totalfile = 0;
              this.outboxdata = [];
              this.buff = [];
            }
            this.loaddataprogress = false;
          } else {
            this.loaddataprogress = false;
            this.totalfile = 0;
          }
        })
        .catch(error => {
          this.loaddataprogress = false;
          this.totalfile = 0;
          console.log(error);
        });
    },
    clickstatus(val) {
      let sortStatus = "";
      this.page = 1;
      if (val === "All") {
        this.sortStatus = "";
      } else if (val === "Active") {
        this.sortStatus = "active";
      } else if (val === "Pending") {
        this.sortStatus = "pending";
      } else if (val === "Reject") {
        // this.sortStatus = "reject";
        this.sortStatus = 'receiver_denied_request'
      }else if(val === "Request"){
        this.sortStatus = 'request'
      }else if(val === "Unsend"){
        this.sortStatus = 'unsend'
      }else if(val === "Duplicate"){
        this.sortStatus = 'duplicate'
      }else if(val === 'Request For Removal Sent'){
        this.sortStatus = 'request_for_removal_sent'
      }else if (val === "Resend From Etax") {
        this.sortStatus = "resend";
      }else if (val === "Sender Delete") {
        this.sortStatus = "sender_delete";
      }
      console.log(this.sortStatus);

      this.getSourceSystem();
    },
    exportfilecsv() {
      // this.callexportfilecsv()
      let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
          } else {
            this.callexportfilecsv(this.$route.query.biz, "2");
          }
        } else {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.callexportfilecsv(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
          } else {
            this.callexportfilecsv(this.$route.query.biz, "2");
          }
        }
      } else {
        this.callexportfilecsv(this.dataAccountId, "1");
      }
    },

    // อันเก่าแบบ 3 step
    // async callexportfilecsv(businessid, _type) {
    //  this.percentCompleted = 0;
    //  this.count_loadingfile = 0;
    //  this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
    //    let payload = {
   
    //       sender_id: [businessid],
    //       sender_type: _type,
    //       filename: this.filename,
    //       fileextension: this.fileextension,
    //       doc_no_start: this.documentid,
    //       doc_no_end: this.documentidto,
    //       doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
    //       doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
    //       doc_type: this.documenttype,
    //       cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
    //       cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
    //       system: this.$route.query.systemid,
    //       sender_name: this.sendername,
    //       sellerTaxid: this.sellertaxid,
    //       sellerBranchid: this.sellerbranchid,
    //       receiver_name: this.receivername,
    //       receiverTaxid: this.buyertaxid,
    //       receiverBranchid: this.buyerbranchid,
    //       status:this.sortStatus,
    //       customer_box: "",
    //       status_onechat: [],
    //       type_downloads : this.typedownloads == true ? 'Y' : 'N',
    //       type_document : this.typeitem == true ? 'Y' : 'N',
    //       sort_by: this.sort_by,
    //       direction: this.direction,
          
    //   };
    //   // this.snackbardowloadonetotal = true;
    //   this.opensnackbarmultidownload = true;
    //   this.timeout = -1;
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code},
       
    //     }
    //   await this.axios
    //     .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/prepare/download_csv_file_outbox_byfilter", payload, config)
    //     .then((response) => {
    //       if (response.data.status === "OK"  && response.data.total > 0) {
    //         console.log("response", response);
    //         this.fn_loop_multidownloading_csv(response.data.all_file, response.data.zip_name, response.data.url);  
    //         this.count_allfile = response.data.total;
    //       } else {
    //         // this.snackbardowloadonetotal = false;
    //         this.opensnackbarmultidownload = false;
    //         if (response.data.total <= 0) {
    //           Toast.fire({
    //             icon: "error",
    //             title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
    //           });
    //         } else {
    //           Toast.fire({
    //             icon: "error",
    //             title: response.data.errorMessage,
    //           });
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       this.opensnackbarmultidownload = false;
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFiles + " นี้ได้",
    //       });
    //       console.log(error);
    //     });
    // },

    // แบบใหม่ยิง 2 เส้น 1.จำนวนไฟล์ที่ดาวน์โหลด 2.ดาวน์โหลดไฟล์
    async callexportfilecsv(businessid, _type) {
      // this.percentCompleted = 0;
      this.percent = 0;
      this.count_allfile = 0;
      // this.count_loadingfile = 0;
      this.showtotalfileonly = true;
      // this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";      
      let offset = this.size * (this.page - 1);
      let payload = {
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        limit: this.size.toString(),
        offset: offset.toString(),
        sender_id: [businessid],
        sender_type: _type,
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name: this.sendername  === null ? "" : this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        order_by: "test",
        sort_by: this.sort_by,
        direction: this.direction,
        inbox_page: this.dataAccountActive.type === 'Citizen' ? "1" : "2"
      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},       
      }
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/V2/count_all_download_file_outbox_byfilter", payload, config)
        .then((response) => {
          if (response.data.status === "ER") {
            this.opensnackbarmultidownload = false;
            this.showtotalfileonly = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          } else {
            if (response.data.total <= 0) {
              this.opensnackbarmultidownload = false;
              Toast.fire({
                icon: "error",
                title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
              });
            } else {
              console.log("response--", response);
              this.count_allfile = response.data.total;
              this.download_file_csv(businessid, _type);
            }
          }
        })
        .catch((error) => {
          this.opensnackbarmultidownload = false;
          this.showtotalfileonly = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilescsv + " นี้ได้",
          });
          console.log(error);
        });
    },
    async download_file_csv(businessid, _type) {
      this.percentCompleted = 0;
      this.percent = 0;
      // this.count_allfile = 0;
      // this.count_loadingfile = 0;
       let payload = {
          sender_id: [businessid],
          sender_type: _type,
          filename: this.filename,
          fileextension: this.fileextension,
          doc_no_start: this.documentid,
          doc_no_end: this.documentidto,
          doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
          doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
          doc_type: this.documenttype,
          cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
          cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
          system: this.$route.query.systemid,
          sender_name: this.sendername,
          sellerTaxid: this.sellertaxid,
          sellerBranchid: this.sellerbranchid,
          receiver_name: this.receivername,
          receiverTaxid: this.buyertaxid,
          receiverBranchid: this.buyerbranchid,
          status:this.sortStatus,
          customer_box: "",
          status_onechat: [],
          type_downloads : this.typedownloads == true ? 'Y' : 'N',
          type_document : this.typeitem == true ? 'Y' : 'N',
          sort_by: this.sort_by,
          direction: this.direction,          
      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},
        onDownloadProgress: (progressEvent) => {
          let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (progresspercent >= 99) {
            this.percentCompleted = 99;
          } else {
            this.percentCompleted = progresspercent;
          }
          this.percent = this.percentCompleted;
          console.log("percent", this.percent);
        },
        withCredentials: false,
        responseType: "arraybuffer",
      }
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/V2/download_csv_file_outbox_byfilter", payload, config)
        .then((response) => {
          if (response.headers["content-type"] === "application/json") {
            this.opensnackbarmultidownload = false;
            this.showtotalfileonly = false;
            let res_error = gbfdecodearraybuffer.decodearraybuffer(
              response.data
            );
            Toast.fire({
              icon: "error",
              title: res_error.errorMessage,
            });
          } else {
            setTimeout(() => {
              this.percent = 100;
              console.log("percent100", this.percent);
            }, 1000);
            // this.percentCompleted = 100;
            // this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
            this.btsdownload = false;
            let headers = response.headers;
            let blob = new Blob([response.data], {
              type: headers["content-type"],
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =
              format(new Date(), "dd_MM_yyyy") + "_Outbox_CSV";
            link.click();
            setTimeout(() => {
              this.opensnackbarmultidownload = false;
              this.showtotalfileonly = false;
            }, 2500);
          }
        })
        .catch((error) => {
          this.opensnackbarmultidownload = false;
          this.showtotalfileonly = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilescsv + " นี้ได้",
          });
          console.log(error);
        });
    },
    async fn_loop_multidownloading_csv(all_file,zip_name,url){
      console.log("all_file",all_file);
      console.log("zip_name",zip_name);
      console.log("url",url);


       let total = 0;

      if (all_file.length === 0) {
        this.percent = 99;
        // console.log(url);
        // window.location.assign(url);
        // this.opensnackbarmultidownload = false;
        // this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          this.statusloading = "กำลังสร้างไฟล์ CSV";
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          this.count_loadingfile += 1;
          total += (1 / all_file.length) * 100;
          let res = await this.downloading_multi_new_csv(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            url
          );
          console.log(res);

          if (this.percent >= 99) {
            this.percent = 99;
          } else {
            this.percent = Math.round(total);
          }
          this.newpercen = this.percent;
          this.namefile = this.OutboxFile + "_" + this.datetime;
          console.log("this.percent",this.percent);
          console.log(this.percentCompleted);
          // if(res.status === "OK" ){

          // }else{
          //   continue;
   
          // }
           if(res.status !== "OK" ){
             console.log("res",res);
            this.snackbar_download_error = true;
            this.filename_error = res.filename
            this.errorMessage = res.errorMessage
            this.snackbars.push({
              filename:this.filename_error ,
              error:this.errorMessage
            })
            // continue;
          }
          console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            this.percent = 99;
            console.log(url);
            // window.location.assign(url);
            this.download_finish_csv(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              url
            );
            // this.opensnackbarmultidownload = false;
          }
        }
      }
    },
    downloading_multi_new_csv(file_id, location_file, zip_name, all_file, url){
      let url_ =
        url + "&file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file;

         console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url_, {}).then((response) => {
          console.log("testtt", response.data.status);
          // if(response.data.status !== "OK"){
          //   console.log();
          // }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
              errorMessage:response.data.errorMessage,
              filename:response.data.filename
            });
          }, 1);
        });
      });
    },
    download_finish_csv(file_id,location_file,zip_name,path,url){
      let zip = url.split('zip_id=');
      let zipid = zip[1]
      console.log("url",zip);
      let payload = {
        account_id:this.dataAccountId,
        zip_id: zipid,
        name:this.namefile,
        path:path,
        type_document : this.typeitem == true ? 'Y' : 'N'
      }
      console.log("payload",payload);
      return new Promise((resolve, reject) => {
        this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_outbox_csv_finish", payload,{
          withCredentials: false,
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.statusloading = "กำลังรวมไฟล์";
          // console.log("testtt", response.data.status);
          // if (response.statusText === "OK") {
          if (response.status === 200) {
            console.log("response++++++++++++ OK", response);
            // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
            if (
              parseInt(response.headers["content-length"]) < 700 &&
              (response.headers["content-type"]) === "application/json"
            ) {
              console.log("response < 1000", response);
              this.opensnackbarmultidownload = false;
              // this.snackbardowloadonetotal = false;
              let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
              console.log("responseerror", res_error);
              Toast.fire({
                icon: "error",
                title: res_error.errorMessage,
              });
            } else {
              this.percent= 100;
              this.percentCompleted = 100;
              this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
              this.btsdownload = false;
              let headers = response.headers;
              let blob = new Blob([response.data], { type: headers["content-type"] });
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              // link.download = this.OutboxFile + "_" + this.datetime;
              link.download = format(new Date(), "dd_MM_yyyy") + '_Outbox_CSV';
              link.click();
              setTimeout(() => {
                this.opensnackbarmultidownload = false;
                // this.snackbardowloadonetotal = false;
              }, 2500);
            }
          } else {
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
          this.typedownloads = false;
          this.typeitem = false;

        });
      });
    },
    // async callexportfilecsv(_accountid, _type) {
    //   this.percentCompleted = 0;
    //   let payload = {
    //     account_id: this.dataAccountId,
    //     sender_id: [_accountid],
    //     sender_type: _type,
    //     filename: this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid,
    //     doc_no_end: this.documentidto,
    //     doc_date_start: this.formatDate(this.fromdatedocument),
    //     doc_date_end: this.formatDate(this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDate(this.fromdateupload),
    //     cre_dtm_end: this.formatDate(this.todateupload),
    //     sender_name: this.sendername,
    //     system: this.$route.query.systemid,
    //     sellerTaxid: this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid,
    //     receiver_name: this.receivername,
    //     receiverTaxid: this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid,
    //     status:this.sortStatus
    //   };
    //   console.log("businessoutbox", payload);
    //   this.snackbardowloadonetotalcsv = true;
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code},
    //      onDownloadProgress: progressEvent => {
    //           let progresspercent = Math.round(
    //             (progressEvent.loaded * 100) / progressEvent.total
    //           );
    //           // console.log(progresspercent);
    //           if (progresspercent === 95) {
    //             this.percentCompleted = 95;
    //           } else {
    //             this.percentCompleted = progresspercent;
    //           }
    //         },
    //         withCredentials: false,
    //         responseType: "arraybuffer"
    //     }
    //   await this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_API +
    //         "/api/download_csv_file_outbox_byfilter",
    //       payload,config
    //     )
    //     .then(response => {
    //       if (response.statusText === "OK") {
    //         if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
    //             console.log("response < 1000", response);
    //             this.snackbardowloadonetotalcsv = false;
    //             let res_error = gbfdecodearraybuffer.decodearraybuffer(
    //               response.data
    //             );
    //             console.log("responseerror", res_error);
    //             Toast.fire({
    //               icon: "error",
    //               title: res_error.errorMessage,
    //             });
    //           } else {

    //             this.percentCompleted = 100;
    //             // const blob = new Blob([response.data]);
    //             // const content = response.headers["content-type"];
    //             // saveAs(blob, this.ExportOutboxFilescsv + ".zip");
    //             let headers = response.headers;
    //             let blob = new Blob([response.data],{type:headers['content-type']});
    //             let link = document.createElement('a');
    //             link.href = window.URL.createObjectURL(blob);
    //             link.download = this.ExportOutboxFilescsv;
    //             link.click();
    //             setTimeout(() => {
    //               this.snackbardowloadonetotalcsv = false;
    //             }, 2500);
    //           }
    //       }
    //     })
    //     .catch(error => {
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilescsv + " นี้ได้"
    //       });
    //       console.log(error);
    //     });
    // },
    
    exportfileexcel(){
      let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
          } else {
            this.callexportfileexcel(this.$route.query.biz, "2");
          }
        } else {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.callexportfileexcel(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
          } else {
            this.callexportfileexcel(this.$route.query.biz, "2");
          }
        }
      } else {
        this.callexportfileexcel(this.dataAccountId, "1");
      }
    },
    // อันเก่าแบบ 3 step
    // async callexportfileexcel(_accountid, _type) {
    //  this.percentCompleted = 0;
    //  this.count_loadingfile = 0;
    //  this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
    //    let payload = {
    //       // account_id: this.dataAccountId,
    //       sender_id: [_accountid],
    //       sender_type: _type,
    //       filename: this.filename,
    //       fileextension: this.fileextension,
    //       doc_no_start: this.documentid,
    //       doc_no_end: this.documentidto,
    //       doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
    //       doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
    //       doc_type: this.documenttype,
    //       cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
    //       cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
    //       system: this.$route.query.systemid,
    //       sender_name: this.sendername,
    //       sellerTaxid: this.sellertaxid,
    //       sellerBranchid: this.sellerbranchid,
    //       receiver_name: this.receivername,
    //       receiverTaxid: this.buyertaxid,
    //       receiverBranchid: this.buyerbranchid,
    //       status:this.sortStatus,
    //       customer_box: "",
    //       status_onechat: [],
    //       type_downloads : this.typedownloads == true ? 'Y' : 'N',
    //       type_document : this.typeitem == true ? 'Y' : 'N',
    //       sort_by: this.sort_by,
    //       direction: this.direction,
    //   };
    //   // this.snackbardowloadonetotal = true;
    //   this.opensnackbarmultidownload = true;
    //   this.timeout = -1;
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code},
       
    //     }
    //   await this.axios
    //     .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/prepare/download_excel_file_outbox_byfilter", payload, config)
    //     .then((response) => {
    //       if (response.data.status === "OK" && response.data.total > 0) {
    //         console.log("response", response);
    //         this.fn_loop_multidownloading_excel(response.data.all_file, response.data.zip_name, response.data.url);  
    //         this.count_allfile = response.data.total;
    //       } else {
    //         // this.snackbardowloadonetotal = false;
    //         this.opensnackbarmultidownload = false;
    //         if (response.data.total <= 0) {
    //           Toast.fire({
    //             icon: "error",
    //             title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
    //           });
    //         } else {
    //           Toast.fire({
    //             icon: "error",
    //             title: response.data.errorMessage,
    //           });
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       this.opensnackbarmultidownload = false;
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFiles + " นี้ได้",
    //       });
    //       console.log(error);
    //     });
    // },

    // แบบใหม่ยิง 2 เส้น 1.จำนวนไฟล์ที่ดาวน์โหลด 2.ดาวน์โหลดไฟล์
    async callexportfileexcel(_accountid, _type) {
      // this.percentCompleted = 0;
      this.percent = 0;
      this.count_allfile = 0;
      // this.count_loadingfile = 0;
      this.showtotalfileonly = true;
      // this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";      
      let offset = this.size * (this.page - 1);
      let payload = {
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        limit: this.size.toString(),
        offset: offset.toString(),
        sender_id: [_accountid],
        sender_type: _type,
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name: this.sendername  === null ? "" : this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        order_by: "test",
        sort_by: this.sort_by,
        direction: this.direction,
        inbox_page: this.dataAccountActive.type === 'Citizen' ? "1" : "2"
      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},       
      }
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/V2/count_all_download_file_outbox_byfilter", payload, config)
        .then((response) => {
          if (response.data.status === "ER") {
            this.opensnackbarmultidownload = false;
            this.showtotalfileonly = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          } else {
            if (response.data.total <= 0) {
              this.opensnackbarmultidownload = false;
              Toast.fire({
                icon: "error",
                title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
              });
            } else {
              console.log("response--", response);
              this.count_allfile = response.data.total;
              this.download_file_excel(_accountid, _type);
            }
          }
        })
        .catch((error) => {
          this.opensnackbarmultidownload = false;
          this.showtotalfileonly = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilesexcel + " นี้ได้",
          });
          console.log(error);
        });
    },
    async download_file_excel(_accountid, _type) {
      this.percentCompleted = 0;
      this.percent = 0;
      // this.count_allfile = 0;
      // this.count_loadingfile = 0;
       let payload = {
          // account_id: this.dataAccountId,
          sender_id: [_accountid],
          sender_type: _type,
          filename: this.filename,
          fileextension: this.fileextension,
          doc_no_start: this.documentid,
          doc_no_end: this.documentidto,
          doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
          doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
          doc_type: this.documenttype,
          cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
          cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
          system: this.$route.query.systemid,
          sender_name: this.sendername,
          sellerTaxid: this.sellertaxid,
          sellerBranchid: this.sellerbranchid,
          receiver_name: this.receivername,
          receiverTaxid: this.buyertaxid,
          receiverBranchid: this.buyerbranchid,
          status:this.sortStatus,
          customer_box: "",
          status_onechat: [],
          type_downloads : this.typedownloads == true ? 'Y' : 'N',
          type_document : this.typeitem == true ? 'Y' : 'N',
          sort_by: this.sort_by,
          direction: this.direction,
      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},
        onDownloadProgress: (progressEvent) => {
          let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (progresspercent >= 99) {
            this.percentCompleted = 99;
          } else {
            this.percentCompleted = progresspercent;
          }
          this.percent = this.percentCompleted;
          console.log("percent", this.percent);
        },
        withCredentials: false,
        responseType: "arraybuffer",
      }
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/V2/download_excel_file_outbox_byfilter", payload, config)
        .then((response) => {
          if (response.headers["content-type"] === "application/json") {
            this.opensnackbarmultidownload = false;
            this.showtotalfileonly = false;
            let res_error = gbfdecodearraybuffer.decodearraybuffer(
              response.data
            );
            Toast.fire({
              icon: "error",
              title: res_error.errorMessage,
            });
          } else {
            setTimeout(() => {
              this.percent = 100;
              console.log("percent100", this.percent);
            }, 1000);
            // this.percentCompleted = 100;
            // this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
            this.btsdownload = false;
            let headers = response.headers;
            let blob = new Blob([response.data], {
              type: headers["content-type"],
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download =
              format(new Date(), "dd_MM_yyyy") + "_Outbox_Excel";
            link.click();
            setTimeout(() => {
              this.opensnackbarmultidownload = false;
              this.showtotalfileonly = false;
            }, 2500);
          }
        })
        .catch((error) => {
          this.opensnackbarmultidownload = false;
          this.showtotalfileonly = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilesexcel + " นี้ได้",
          });
          console.log(error);
        });
    },
    async fn_loop_multidownloading_excel(all_file,zip_name,url){
      console.log("all_file",all_file);
      console.log("zip_name",zip_name);
      console.log("url",url);


       let total = 0;

      if (all_file.length === 0) {
        this.percent = 99;
        // console.log(url);
        // window.location.assign(url);
        // this.opensnackbarmultidownload = false;
        // this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          this.statusloading = "กำลังสร้างไฟล์ Excel";
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          this.count_loadingfile += 1;
          total += (1 / all_file.length) * 100;
          let res = await this.downloading_multi_new_excel(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            url
          );
          console.log(res);

          if (this.percent >= 99) {
            this.percent = 99;
          } else {
            this.percent = Math.round(total);
          }
          this.newpercen = this.percent;
          this.namefile = this.OutboxFile + "_" + this.datetime;
          console.log("this.percent",this.percent);
          console.log(this.percentCompleted);
          // if(res.status === "OK" ){

          // }else{
          //   continue;
   
          // }
           if(res.status !== "OK" ){
             console.log("res",res);
            this.snackbar_download_error = true;
            this.filename_error = res.filename
            this.errorMessage = res.errorMessage
            this.snackbars.push({
              filename:this.filename_error ,
              error:this.errorMessage
            })
            // continue;
          }
          console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            this.percent = 99;
            console.log(url);
            // window.location.assign(url);
            this.download_finish_excel(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              url
            );
            // this.opensnackbarmultidownload = false;
          }
        }
      }
    },
    downloading_multi_new_excel(file_id, location_file, zip_name, all_file, url){
      let url_ =
        url + "&file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file;

         console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url_, {}).then((response) => {
          console.log("testtt", response.data.status);
          // if(response.data.status !== "OK"){
          //   console.log();
          // }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
              errorMessage:response.data.errorMessage,
              filename:response.data.filename
            });
          }, 1);
        });
      });
    },
    download_finish_excel(file_id,location_file,zip_name,path,url){
      let zip = url.split('zip_id=');
      let zipid = zip[1]
      console.log("url",zip);
      let payload = {
        account_id:this.dataAccountId,
        zip_id: zipid,
        name:this.namefile,
        path:path,
        type_document : this.typeitem == true ? 'Y' : 'N'
      }
      console.log("payload",payload);
      return new Promise((resolve, reject) => {
        this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_file_excel_finish_outbox", payload,{
          withCredentials: false,
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.statusloading = "กำลังรวมไฟล์";
          // console.log("testtt", response.data.status);
          // if (response.statusText === "OK") {
          if (response.status === 200) {
            console.log("response++++++++++++ OK", response);
            // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
            if (
              parseInt(response.headers["content-length"]) < 700 &&
              (response.headers["content-type"]) === "application/json"
            ) {
              console.log("response < 1000", response);
              this.opensnackbarmultidownload = false;
              // this.snackbardowloadonetotal = false;
              let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
              console.log("responseerror", res_error);
              Toast.fire({
                icon: "error",
                title: res_error.errorMessage,
              });
            } else {
              this.percent= 100;
              this.percentCompleted = 100;
              this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
              this.btsdownload = false;
              let headers = response.headers;
              let blob = new Blob([response.data], { type: headers["content-type"] });
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              // link.download = this.OutboxFile + "_" + this.datetime;
              link.download = format(new Date(), "dd_MM_yyyy") + '_Outbox_Excel';
              link.click();
              setTimeout(() => {
                this.opensnackbarmultidownload = false;
                // this.snackbardowloadonetotal = false;
              }, 2500);
            }
          } else {
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
          this.typedownloads = false;
          this.typeitem = false;

        });
      });
    },
    // async callexportfileexcel(_accountid, _type) {
    //   this.percentCompleted = 0;
    //   let payload = {
    //     account_id: this.dataAccountId,
    //     sender_id: [_accountid],
    //     sender_type: _type,
    //     filename: this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid,
    //     doc_no_end: this.documentidto,
    //     doc_date_start: this.formatDate(this.fromdatedocument),
    //     doc_date_end: this.formatDate(this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDate(this.fromdateupload),
    //     cre_dtm_end: this.formatDate(this.todateupload),
    //     sender_name: this.sendername,
    //     system: this.$route.query.systemid,
    //     sellerTaxid: this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid,
    //     receiver_name: this.receivername,
    //     receiverTaxid: this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid,
    //     status:this.sortStatus
    //   };
    //   console.log("businessoutbox", payload);
    //   this.snackbardowloadonetotalexcel = true;
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code},
    //       onDownloadProgress: progressEvent => {
    //           let progresspercent = Math.round(
    //             (progressEvent.loaded * 100) / progressEvent.total
    //           );
    //           // console.log(progresspercent);
    //           if (progresspercent === 95) {
    //             this.percentCompleted = 95;
    //           } else {
    //             this.percentCompleted = progresspercent;
    //           }
    //         },
    //         withCredentials: false,
    //         responseType: "arraybuffer"
    //     }
    //   await this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_API +
    //         "/api/download_excel_file_outbox_byfilter",
    //       payload,config
    //     )
    //     .then(response => {
    //       if (response.statusText === "OK") {
    //         if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
    //             console.log("response < 1000", response);
    //             this.snackbardowloadonetotalexcel = false;
    //             let res_error = gbfdecodearraybuffer.decodearraybuffer(
    //               response.data
    //             );
    //             console.log("responseerror", res_error);
    //             Toast.fire({
    //               icon: "error",
    //               title: res_error.errorMessage,
    //             });
    //           } else {

    //             this.percentCompleted = 100;
    //             // const blob = new Blob([response.data]);
    //             // const content = response.headers["content-type"];
    //             // saveAs(blob, this.ExportOutboxFilesexcel + ".zip");
    //             let headers = response.headers;
    //             let blob = new Blob([response.data],{type:headers['content-type']});
    //             let link = document.createElement('a');
    //             link.href = window.URL.createObjectURL(blob);
    //             link.download = this.ExportOutboxFilesexcel;
    //             link.click();
    //             setTimeout(() => {
    //               this.snackbardowloadonetotalexcel = false;
    //             }, 2500);
    //           }
    //       }
    //     })
    //     .catch(error => {
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFilesexcel + " นี้ได้"
    //       });
    //       console.log(error);
    //     });
    // },
    filteredItems(search) {
      this.search = search;
    },
    checkSort() {
      // this.loadfolder();
      this.outboxdata = this.buff;
      this.clickSort = false;
      this.multiSort = false;
      // this.multiSortdate=false
      // this.multiSortsize=false
    },
    sortFile(parameter) {
      this.folderSort = [];
      this.fileSort = [];
      this.sortdata = [];
      this.outboxdata = [];
      let i, j;
      for (i = 0; i < parameter.length; i++) {
        if (parameter[i].data_type === "folder") {
          this.folderSort.push(parameter[i]);
        } else this.fileSort.push(parameter[i]);
      }
      if (this.sortby === "name") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.filename.localeCompare(b.filename);
          });
          this.fileSort.sort(function(a, b) {
            return a.filename.localeCompare(b.filename);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.filename.localeCompare(a.filename);
          });
          this.fileSort.sort(function(a, b) {
            return b.filename.localeCompare(a.filename);
          });
        }
      } else if (this.sortby === "outboxdate") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.cre_dtm.localeCompare(b.cre_dtm);
          });
          this.fileSort.sort(function(a, b) {
            return a.cre_dtm.localeCompare(b.cre_dtm);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.cre_dtm.localeCompare(a.cre_dtm);
          });
          this.fileSort.sort(function(a, b) {
            return b.cre_dtm.localeCompare(a.cre_dtm);
          });
        }
      } else if (this.sortby === "docdate") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.document_date.localeCompare(b.document_date);
          });
          this.fileSort.sort(function(a, b) {
            return a.document_date.localeCompare(b.document_date);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.document_date.localeCompare(a.document_date);
          });
          this.fileSort.sort(function(a, b) {
            return b.document_date.localeCompare(a.document_date);
          });
        }
      } else if (this.sortby === "docno") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.document_no.localeCompare(b.document_no);
          });
          this.fileSort.sort(function(a, b) {
            return a.document_no.localeCompare(b.document_no);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.document_no.localeCompare(a.document_no);
          });
          this.fileSort.sort(function(a, b) {
            return b.document_no.localeCompare(a.document_no);
          });
        }
      }

      this.sortdata.push(this.folderSort);
      this.sortdata.push(this.fileSort);
      for (i = 0; i < this.sortdata.length; i++) {
        for (j = 0; j < this.sortdata[i].length; j++) {
          this.outboxdata.push(this.sortdata[i][j]);
        }
      }
      return this.outboxdata;
    },
    clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    async multipledownload() {
      var d = new Date();
      let datetime =
        d.toISOString().slice(0, 10) +
        " " +
        "at" +
        " " +
        this.zeroPadding(d.getHours(), 2) +
        "-" +
        this.zeroPadding(d.getMinutes(), 2) +
        "-" +
        this.zeroPadding(d.getSeconds(), 2);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          type: "warning",
          title: this.$t("myoutboxPage.msg.nofile")
        });
      } else {
        this.$store.dispatch("show_loading", true);
        let filedata = [];
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          filedata.push(this.filemultipledownload[i]["file_id"]);
        }

        let payload = {
          list_file: filedata,
          account_id: this.dataAccountId
        };
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
              "/api/multiple_downloads_file",
            payload,
            {
              headers: { Authorization: auth.code },
              withCredentials: false,
              responseType: "blob"
            },
          )
          .then(response => {
            this.statusmutipledownload = false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "OutboxFile" + " " + datetime + ".zip"
            );
            document.body.appendChild(link);
            link.click();
            this.$store.dispatch("show_loading", false);
            this.clearmultipledownload();
          })
          .catch(error => {
            this.$store.dispatch("show_loading", false);
            Toast.fire({
              icon: "error",
              title: this.$t("myoutboxPage.msg.apierror")
            });
            console.log(error);
          });
      }
    },
    clickpagination() {
      if(this.checkpagination === true){
        this.checksortorderby();
      }else{
        this.getSourceSystem();
      }
    },
    // downloadfile(currentfile) {
    //   if (currentfile.file_type === "folder") {
    //     Toast.fire({
    //       type: "warning",
    //       title: "Coming Soon !!"
    //     });
    //   } else {
    //     let url =
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
    //       "/api/v2/download_file?user_id=" +
    //       this.dataUsername +
    //       "&file_id=" +
    //       currentfile.file_id;
    //     window.location.assign(url);
    //   }
    // },
    // fn_calculate_size(_sizefilebyte) {
    //   console.log("ขนาดไฟล์เป็น GB",(_sizefilebyte / 1073741824).toFixed(2));
    //    return parseInt((_sizefilebyte / 1073741824).toFixed(2))
    // },
    downloadfile(currentfile) {
      console.log(currentfile);
      // let checkfilesize = this.fn_calculate_size(currentfile.file_size)
      this.percentCompleted = 0;
      if (currentfile.file_type === "folder") {
        Toast.fire({
          icon: "warning",
          title: "Coming Soon !!"
        });
      } else {
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: currentfile.file_id
        };

        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/v2/download_file_inbox?user_id=" +
          // "/api/v2/download_file?user_id=" +
          this.dataUsername +
          "&file_id=" +
          currentfile.file_id;
        // if(checkfilesize < 1.00){
         this.snackbardowloadonefile = true;
         this.namedowload = currentfile.filename;
         this.axios
            .get(url,{
              onDownloadProgress: (progressEvent) => {
                let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                // console.log(progresspercent);        
                 if (progresspercent === 95) {
                  this.percentCompleted = 95;
                  }else{
                    this.percentCompleted = progresspercent;
                  }
                
              },
              withCredentials: false,
              responseType: "arraybuffer" 
            })
            .then(response => {
              // if(response.statusText === "OK"){
              if(response.status === 200){
                if (parseInt(response.headers["content-length"]) < 700 && (response.headers["content-type"]) === "application/json") {
                  console.log("response < 1000", response);
                  this.snackbardowloadonefile = false;
                  let res_error = gbfdecodearraybuffer.decodearraybuffer(
                    response.data
                  );
                  console.log("responseerror", res_error);
                  Toast.fire({
                    icon: "error",
                    title: res_error.errorMessage,
                  });
                } else {
                  this.percentCompleted = 100;  
                  let headers = response.headers;
                  let blob = new Blob([response.data],{type:headers['content-type']});
                  let link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  link.download = currentfile.filename;
                  link.click();
                    setTimeout(() => {
                  this.snackbardowloadonefile = false;
                }, 2500);
                }
              }else{
                this.snackbardowloadonefile = false;
                Toast.fire({
                  icon: "error",
                  title: this.$t("toast.cannotconnectonebox"),
                });
              }
              // const blob = new Blob([response.data]);
              // const content = response.headers['content-type'];
              // saveAs(blob,currentfile.filename)
                
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลด " + currentfile.filename + " นี้ได้"
              });
              console.log(error);
            });
        // }else{
        //   window.location.assign(url)
        //   this.btsdownload = false;
        //   this.snackbardowloadonefile = false;
        // }
      }
    },
    previewfile(file) {
      // console.log(file);
      this.currentfile = file;
      this.openpreviewfile = true;
    },
    loadstorage() {
      this.$store.dispatch("check_storage", this.dataAccountId).then(res => {});
    },
    changeFromdateupload() {
      this.$refs.dialogFromdateupload.save(this.fromdateupload);
      // this.todateupload = "";
      this.todateupload = this.formatDatetodoc2(this.fromdateupload);
    },
    changeFromdatedocument() {
      this.$refs.dialogFromdatedocument.save(this.fromdatedocument);
      // this.todatedocument = "";
      this.todatedocument = this.formatDatetodoc2(this.fromdatedocument);
    },
    formatDatetodoc2(datefrom) {
      console.log("datefrom",datefrom);
      // console.log("docto",docto);
      var dateNow = new Date();
      var date = new Date(datefrom);
        console.log('1');
        if(datefrom){
          console.log('2');
          console.log("datefrom", datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            let todate = new Date().toISOString().substr(0, 10);
            // this.todatedocument = new Date().toISOString().substr(0, 10);
            const [year, month, day] = todate.split("-");
            return `${year}-${month}-${day}`;
          }else{
            console.log('4');
            let todate = new Date(date).toISOString().substr(0, 10);
            // this.todatedocument = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = todate.split("-");
            return `${year}-${month}-${day}`;
          }
        }

    },
    getSourceSystem() {
      let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.$router.push("/myoutbox-system");
          } else {
            this.getDataExtensionFile(this.$route.query.biz, "2");
            this.getDataDocType(this.$route.query.biz, "2");
            this.getDataOutbox(this.$route.query.biz, "2");
          }
        } else {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.getDataExtensionFile(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
            this.getDataDocType(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
            this.getDataOutbox(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
          } else {
            this.getDataExtensionFile(this.$route.query.biz, "2");
            this.getDataDocType(this.$route.query.biz, "2");
            this.getDataOutbox(this.$route.query.biz, "2");
          }
        }
      } else {
        this.getDataDocType(this.dataAccountId, "1");
        this.getDataOutbox(this.dataAccountId, "1");
        this.getDataExtensionFile(this.dataAccountId, "1");
        // if (this.dataAccountActive["type"] === "Business") {
        //   this.loadSourceSystem(_accountid, _type)
        // } else {
        //   this.loadSourceSystem(_accountid, _type)
        // }
      }
    },
   async getDataOutbox(_accountid, _type) {
      // if(this.statusde == 'Active'){
      //    this.sortStatus = 'active'
      // }
      this.loaddataprogress = true;
      let offset = this.size * (this.page - 1);
      let payload = {
        po_number: this.ponumberinput === null ? "" : this.ponumberinput,
        limit: this.size.toString(),
        offset: offset.toString(),
        sender_id: [_accountid],
        sender_type: _type,
        filename: this.filename === null ? "" : this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid === null ? "" : this.documentid,
        doc_no_end: this.documentidto === null ? "" : this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        sender_name: this.sendername  === null ? "" : this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid === null ? "" : this.sellertaxid,
        sellerBranchid: this.sellerbranchid === null ? "" : this.sellerbranchid,
        receiver_name: this.receivername === null ? "" : this.receivername,
        receiverTaxid: this.buyertaxid === null ? "" : this.buyertaxid,
        receiverBranchid: this.buyerbranchid === null ? "" : this.buyerbranchid,
        status: this.sortStatus,
        order_by: "test",
        sort_by: this.sort_by,
        direction: this.direction,
        inbox_page: this.dataAccountActive.type === 'Citizen' ? "1" : "2"
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v3/get_file_outbox",
          payload,{headers: { Authorization: auth.code },}
        )
        .then(res => {
          if (res.data.status === "OK") {
            console.log("resoutboxbusiness-----",res);
            this.outboxdata = [];
            if ("total_file" in res["data"]["result"]) {
              this.totalfile = res.data.result["total_file"] || 0;
              for(let i=0 ; i < res.data.result.data.length ; i++){
              //   // if(res.data.result.data[i].relation_status === 0 || res.data.result.data[i].relation_status === 2){
                  this.outboxdata.push(res.data.result.data[i])
              //   // }
              }
              console.log("this.outboxdata",this.outboxdata);
              // this.outboxdata = res.data.result["data"];
              this.buff = res.data.result["data"];
            } else {
              this.totalfile = 0;
              this.outboxdata = [];
              this.buff = [];
            }
            this.loaddataprogress = false;
          } else {
            this.loaddataprogress = false;
            this.totalfile = 0;
          }
        })
        .catch(error => {
          this.loaddataprogress = false;
          this.totalfile = 0;
          console.log(error);
        });
    },
    seticon(parameter) {
      // console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel", "green"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word", "primary"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png") {
        dataicon = ["mdi-file-image", "blue-grey"];
      } else if (parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4"||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv")
      {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut"){
        dataicon=["mdi-file-link","pink"]
      } else {
        dataicon = ["mdi-file", "black"];
      }

      return dataicon;
    },
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${year}${month}${day}`;
    },
    formatDatefromdoc(datefrom,docto) {
      console.log("datefrom1",datefrom);
      console.log("dateto2",docto);
      if((!datefrom || datefrom === undefined)&& docto){
        console.log('1');
        if(docto){
          console.log('2');
          console.log("docto", docto);
          var date = new Date(docto);
          date.setDate(date.getDate() - 90);
          console.log("date",date);
          this.fromdatedocument = new Date(date).toISOString().substr(0, 10);
          console.log("this.fromdatedocument",this.fromdatedocument);
          const [year, month, day] = this.fromdatedocument.split("-");
          return `${year}${month}${day}`;
        }
      }else if(datefrom && docto){
        console.log('3');
        const [year, month, day] = datefrom.split("-");
        return `${year}${month}${day}`;
      }else if((!datefrom || datefrom === undefined ) && (!docto || docto === undefined)){
        return ""
      }else if(datefrom && (!docto || docto === undefined)){
        console.log('2');
          console.log("datefrom-", datefrom);
          var dateNow = new Date();
          var date = new Date(datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            this.fromdatedocument =new Date(datefrom).toISOString().substr(0, 10);
            this.todatedocument = new Date().toISOString().substr(0, 10);
            const [year, month, day] = this.fromdatedocument.split("-");
            return `${year}${month}${day}`;
          }else{
            console.log('4');
            this.fromdatedocument =new Date(datefrom).toISOString().substr(0, 10);
            this.todatedocument = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = this.fromdatedocument.split("-");
            return `${year}${month}${day}`;
          }
      }
    },
    formatDatetodoc(datefrom,docto) {
      console.log("datefrom",datefrom);
      console.log("docto",docto);
      var dateNow = new Date();
      var date = new Date(datefrom);
      if(!docto && datefrom){
        console.log('1');
        if(datefrom){
          console.log('2');
          console.log("datefrom", datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            this.todatedocument = new Date().toISOString().substr(0, 10);
            const [year, month, day] = this.todatedocument.split("-");
            return `${year}${month}${day}`;
          }else{
            console.log('4');
            this.todatedocument = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = this.todatedocument.split("-");
            return `${year}${month}${day}`;
          }
        }
      }else if(docto && datefrom){
        console.log('3');
        const [year, month, day] = docto.split("-");
        return `${year}${month}${day}`;
      }else if(!docto && !datefrom){
        return ""
      }
    },
    formatDatefromupload(datefrom,docto) {
      console.log("date",datefrom);
      console.log("date",docto);
      if(!datefrom){
        console.log('1');
        if(docto){
          console.log('2');
          console.log("docto", docto);
          var date = new Date(docto);
          date.setDate(date.getDate() - 90);
          console.log("date",date);
          this.fromdateupload = new Date(date).toISOString().substr(0, 10);
          console.log("this.fromdateupload",this.fromdateupload);
          const [year, month, day] = this.fromdateupload.split("-");
          return `${year}${month}${day}`;
        }
      }else{
        console.log('3');
        const [year, month, day] = datefrom.split("-");
        return `${year}${month}${day}`;
      }
    },
    formatDatetoupload(datefrom,docto) {
      console.log("datefrom",datefrom);
      console.log("docto",docto);
      var dateNow = new Date();
      var date = new Date(datefrom);
      if(!docto && datefrom){
        console.log('1');
        if(datefrom){
          console.log('2');
          console.log("datefrom", datefrom);
          date.setDate(date.getDate() + 90);
          console.log("date",date);
          if(date > dateNow){
            console.log('3');
            this.todateupload = new Date().toISOString().substr(0, 10);
            const [year, month, day] = this.todateupload.split("-");
            return `${year}${month}${day}`;
          }else{
            console.log('4');
            this.todateupload = new Date(date).toISOString().substr(0, 10);
            const [year, month, day] = this.todateupload.split("-");
            return `${year}${month}${day}`;
          }
        }
      }else if(docto && datefrom){
        console.log('3');
        const [year, month, day] = docto.split("-");
        return `${year}${month}${day}`;
      }else if(!docto && !datefrom){
        return ""
      }
    },
    formatdatetime(_datetime) {
      if(_datetime === "" || _datetime === null || _datetime === undefined){
        return "-";
      }else{   
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[8] + _datetime.split("")[9];
        let second = _datetime.split("")[10] + _datetime.split("")[11];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );         
      }
    },
    setformatdate(_date) {
      if(_date === "" || _date === null || _date === undefined){
        return "-";
      }else{
        let data =
          _date.substring(8, 6) +
          "/" +
          _date.substring(6, 4) +
          "/" +
          _date.substring(0, 4);
        return data || "-";
      }   
    },
    getDataFilters() {
      let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          // this.$router.push("/myinbox-system");
        } else {
          this.getDataExtensionFile(
            this.dataAccountActive.business_info["business_id"],
            "2"
          );
          this.getDataDocType(
            this.dataAccountActive.business_info["business_id"],
            "2"
          );
        }
      } else {
        this.getDataDocType(this.dataAccountId, "1");
        this.getDataExtensionFile(this.dataAccountId, "1");
        // if (this.dataAccountActive["type"] === "Business") {
        //   this.loadSourceSystem(_accountid, _type)
        // } else {
        //   this.loadSourceSystem(_accountid, _type)
        // }
      }
    },
    searchdocument() {
      if (
        (this.filename === "" || this.filename === null) &&
        (this.fileextension === "" || this.fileextension === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null)  &&
        (this.receivername === "" || this.receivername === null ) &&
        (this.sendername === "" || this.sendername === null) &&
        this.documenttype.length == 0 &&
        (this.documentid === "" ||this.documentid === null ) &&
        (this.documentidto === "" || this.documentidto === null) &&
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null ) &&
        (this.sellertaxid === "" || this.sellertaxid === null) &&
        (this.sellerbranchid === "" || this.sellerbranchid === null) &&
        (this.buyertaxid === "" || this.buyertaxid === null)&&
        (this.buyerbranchid === "" || this.buyerbranchid === null)
      ){
          this.opendialogalersearchdonotfillout = true;
      }else if(
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null) &&
         (this.documentid === "" || this.documentid === null) 
      ){
        this.opendialogalersearchdonotfillout = true;
      }else if(
        (this.fromdatedocument === "" || this.fromdatedocument === null) &&
        (this.todatedocument === "" || this.todatedocument === null) &&
        (this.fromdateupload === "" || this.fromdateupload === null) &&
        (this.todateupload === "" || this.todateupload === null) && (
        (this.documentid !== "" || this.documentid !== null) ||
        (this.documentidto !== "" || this.documentidto !== null) )
      ){
        this.page =1
      this.panel = false;
      this.getSourceSystem();
      }else if(
        (this.fromdatedocument !== ""  &&
        this.todatedocument !== ""  ) ||
        (this.fromdateupload !== ""  &&
        this.todateupload !== "" )
      ){
        this.page =1
      this.panel = false;
      this.getSourceSystem();
      }else{
        this.page =1
      this.panel = false;
      this.getSourceSystem();
      }
    },
   async getDataDocType(_accountid, _type) {
      let payload = {
        sender_id: _accountid,
        sender_type: _type,
        system: this.$route.query.systemid || ""
      };
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/outbox_get_all_doctype",
          payload,{headers: { Authorization: auth.code },}
        )
        .then(res => {
          if (res.data.status === "OK") {
            this.listDoctype = res.data.result;
          } else {
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    async getDataExtensionFile(_accountid, _type) {
      let payload = {
        sender_id: _accountid,
        sender_type: _type,
        system: this.$route.query.systemid || ""
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/outbox_get_all_fileextension",
          payload,{headers: { Authorization: auth.code },}
        )
        .then(res => {
          if (res.data.status === "OK") {
            this.listExtensionFile = res.data.result;
          } else {
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    exportfile() {
      let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
          } else {
            this.callexportfile(this.$route.query.biz, "2");
          }
        } else {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.callexportfile(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
          } else {
            this.callexportfile(this.$route.query.biz, "2");
          }
        }
      } else {
        this.callexportfile(this.dataAccountId, "1");
      }
    },
    async callexportfile(_accountid, _type) {
      this.percentCompleted = 0;
      this.count_loadingfile = 0;
      this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
      let payload = {
        sender_id: [_accountid],
        sender_type: _type,
        filename: this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid,
        doc_no_end: this.documentidto,
        doc_date_start: this.formatDatefromdoc(this.fromdatedocument,this.todatedocument),
        doc_date_end: this.formatDatetodoc(this.fromdatedocument,this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDatefromupload(this.fromdateupload,this.todateupload),
        cre_dtm_end: this.formatDatetoupload(this.fromdateupload,this.todateupload),
        system: this.$route.query.systemid,
        sender_name: this.sendername,
        sellerTaxid: this.sellertaxid,
        sellerBranchid: this.sellerbranchid,
        receiver_name: this.receivername,
        receiverTaxid: this.buyertaxid,
        receiverBranchid: this.buyerbranchid,
        status:this.sortStatus,
        account_id: this.dataAccountId,
        type_downloads : this.typedownloads == true ? 'Y' : 'N',
        type_document : this.typeitem == true ? 'Y' : 'N'

      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},
        // onDownloadProgress: progressEvent => {
        //       let progresspercent = Math.round(
        //         (progressEvent.loaded * 100) / progressEvent.total
        //       );
        //       // console.log(progresspercent);
        //       if (progresspercent === 95) {
        //         this.percentCompleted = 95;
        //       } else {
        //         this.percentCompleted = progresspercent;
        //       }
        //     },
        //     withCredentials: false,
        //     responseType: "arraybuffer"
      }
      await this.axios
        // .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v2/outbox_downloads_file_byfilter", payload, config)
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/prepare_zip_file_outbox_by_filter", payload, config)
        .then(response => {
          if (response.data.status === "OK") {
            console.log("response", response);
            this.fn_loop_multidownloading(response.data.all_file, response.data.zip_name, response.data.url); 
            this.count_allfile = response.data.total;            
          // if (response.statusText === "OK") {
          //   if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
          //       console.log("response < 1000", response);
          //       this.snackbardowloadonetotal = false;
          //       let res_error = gbfdecodearraybuffer.decodearraybuffer(
          //         response.data
          //       );
          //       console.log("responseerror", res_error);
          //       Toast.fire({
          //         icon: "error",
          //         title: res_error.errorMessage,
          //       });
          //     } else {

          //       this.percentCompleted = 100;
          //       let headers = response.headers;
          //       let blob = new Blob([response.data],{type:headers['content-type']});
          //       let link = document.createElement('a');
          //       link.href = window.URL.createObjectURL(blob);
          //       link.download = this.ExportOutboxFiles;
          //       link.click();
          //       // const blob = new Blob([response.data]);
          //       // const content = response.headers["content-type"];
          //       // saveAs(blob, this.ExportOutboxFiles + ".zip");
          //       setTimeout(() => {
          //         this.snackbardowloadonetotal = false;
          //       }, 2500);
          //     }
          }else{
            // this.snackbardowloadonetotal = false;
            this.opensnackbarmultidownload = false;
            if (response.data.errorCode === "ER400" && response.data.errorMessage === "File not found") {
              Toast.fire({
                icon: "error",
                title: this.$t("myoutboxPage.msg.nodocumentstodownload"),
              });
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          }
        })
        .catch(error => {
          // this.snackbardowloadonetotal = false;
          this.opensnackbarmultidownload = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFiles + " นี้ได้"
          });
          console.log(error);
        });
    },
     async fn_loop_multidownloading(all_file,zip_name,url){
      console.log("all_file",all_file);
      console.log("zip_name",zip_name);
      console.log("url",url);


       let total = 0;

      if (all_file.length === 0) {
        this.percent = 99;
        // console.log(url);
        // window.location.assign(url);
        // this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          this.statusloading = "กำลังดาวน์โหลดไฟล์";
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          this.count_loadingfile += 1;
          total += (1 / all_file.length) * 100;
          let res = await this.downloading_multi_new(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            url
          );
          console.log(res);

          if (this.percent >= 99) {
            this.percent = 99;
          } else {
            this.percent = Math.round(total);
          }
          this.newpercen = this.percent;
          this.namefile = this.OutboxFile + "_" + this.datetime;
          console.log("this.percent",this.percent);
          console.log(this.percentCompleted);
          // if(res.status === "OK" ){

          // }else{
          //   continue;
   
          // }
           if(res.status !== "OK" ){
             console.log("res",res);
            this.snackbar_download_error = true;
            this.filename_error = res.filename
            this.errorMessage = res.errorMessage
            this.snackbars.push({
              filename:this.filename_error ,
              error:this.errorMessage
            })
            // continue;
          }
          console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            this.percent = 99;
            console.log(url);
            // window.location.assign(url);
            this.download_finish(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              url
            );
            // this.opensnackbarmultidownload = false;
          }
        }
      }
    },
    downloading_multi_new(file_id, location_file, zip_name, all_file, url){
      let url_ =
        url + "&file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file;

         console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url_, {}).then((response) => {
          console.log("testtt", response.data.status);
          // if(response.data.status !== "OK"){
          //   console.log();
          // }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
              errorMessage:response.data.errorMessage,
              filename:response.data.filename
            });
          }, 1);
        });
      });
    },
    download_finish(file_id,location_file,zip_name,path,url){
      let zip = url.split('zip_id=');
      let zipid = zip[1]
      console.log("url",zip);
      let payload = {
        account_id:this.dataAccountId,
        zip_id: zipid,
        name:this.namefile,
        path:path,
        type_document : this.typeitem == true ? 'Y' : 'N'
      }
      console.log("payload",payload);
      return new Promise((resolve, reject) => {
        this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_file_inbox_finish", payload,{
          withCredentials: false,
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.statusloading = "กำลังรวมไฟล์";
          // console.log("testtt", response.data.status);
          // if (response.statusText === "OK") {
          if (response.status === 200) {
            console.log("response++++++++++++ OK", response);
            // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
            if (
              parseInt(response.headers["content-length"]) < 700 &&
              (response.headers["content-type"]) === "application/json"
            ) {
              console.log("response < 1000", response);
              this.opensnackbarmultidownload = false;
              this.snackbardowloadonetotal = false;
              let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
              console.log("responseerror", res_error);
              Toast.fire({
                icon: "error",
                title: res_error.errorMessage,
              });
            } else {
              this.percent= 100;
              this.percentCompleted = 100;
              this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
              this.btsdownload = false;
              let headers = response.headers;
              let blob = new Blob([response.data], { type: headers["content-type"] });
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              // link.download = this.OutboxFile + "_" + this.datetime;                  
              link.download = format(new Date(), "dd_MM_yyyy") + '_OutboxFiles';
              link.click();
              setTimeout(() => {
                this.opensnackbarmultidownload = false;
                this.snackbardowloadonetotal = false;
              }, 2500);
            }
          } else {
            this.opensnackbarmultidownload = false;
            this.snackbardowloadonetotal = false;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
          this.typedownloads = false;
          this.typeitem = false;

        });
      });
    },
    callDateNow() {
      let dateNow = new Date();
      return new Date(dateNow).toISOString().substr(0, 10);
    },
    backtopreventfolder() {
      this.$router.push({
        path: "/business/alloutbox-system",
        query: {
          po_number: this.ponumberinput === null ? "" : this.ponumberinput,
          sourcesystem: this.$route.query.sourcesystem,
          systemid: this.$route.query.systemid,
          filename: this.filename,
          fileextension: this.fileextension,
          fromdate: this.fromdateupload,
          todate: this.todateupload,
          fromdatedoc: this.fromdatedocument,
          todatedoc: this.todatedocument,
          doc_no_start: this.documentid,
          doc_no_end: this.documentidto,
          sender_name: this.sendername,
          sellerTaxid: this.sellertaxid,
          sellerBranchid: this.sellerbranchid,
          receiver_name: this.receivername,
          receiverTaxid: this.buyertaxid,
          receiverBranchid: this.buyerbranchid,
        },
      });
    },
    // async callexportfile(_accountid, _type) {
    //   this.percentCompleted = 0;
    //   // this.$store.dispatch("show_loading", true);
    //   let payload = {
    //     sender_id: [_accountid],
    //     sender_type: _type,
    //     filename: this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid,
    //     doc_no_end: this.documentidto,
    //     doc_date_start: this.formatDate(this.fromdatedocument),
    //     doc_date_end: this.formatDate(this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDate(this.fromdateupload),
    //     cre_dtm_end: this.formatDate(this.todateupload),
    //     system: this.$route.query.systemid,
    //     sender_name: this.sendername,
    //     sellerTaxid: this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid,
    //     receiver_name: this.receivername,
    //     receiverTaxid: this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid,
    //     status:this.sortStatus
    //   };
    //   this.snackbardowloadonetotal = true;
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code},
    //       onDownloadProgress: progressEvent => {
    //           let progresspercent = Math.round(
    //             (progressEvent.loaded * 100) / progressEvent.total
    //           );
    //           // console.log(progresspercent);
    //           if (progresspercent === 95) {
    //             this.percentCompleted = 95;
    //           } else {
    //             this.percentCompleted = progresspercent;
    //           }
    //         },
    //         withCredentials: false,
    //         responseType: "arraybuffer"
    //     }
    //   await this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_API +
    //         "/api/v2/outbox_downloads_file_byfilter",
    //       payload,config
    //     )
    //     .then(response => {
    //       if (response.statusText === "OK") {
    //         if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
    //             console.log("response < 1000", response);
    //             this.snackbardowloadonetotal = false;
    //             let res_error = gbfdecodearraybuffer.decodearraybuffer(
    //               response.data
    //             );
    //             console.log("responseerror", res_error);
    //             Toast.fire({
    //               icon: "error",
    //               title: res_error.errorMessage,
    //             });
    //           } else {
    //             this.percentCompleted = 100;
    //             // const blob = new Blob([response.data]);
    //             // const content = response.headers["content-type"];
    //             // saveAs(blob, this.ExportOutboxFiles + ".zip");
    //             let headers = response.headers;
    //             let blob = new Blob([response.data],{type:headers['content-type']});
    //             let link = document.createElement('a');
    //             link.href = window.URL.createObjectURL(blob);
    //             link.download = this.ExportOutboxFiles;
    //             link.click();
    //             setTimeout(() => {
    //               this.snackbardowloadonetotal = false;
    //             }, 2500);
    //           }
    //       }
    //     })
    //     .catch(error => {
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFiles + " นี้ได้"
    //       });
    //       console.log(error);
    //     });
    // }
  },
  mounted() {
    this.ponumberinput = this.$route.query.po_number || "",
    this.fromdateupload = this.$route.query.fromdate || "";
    this.todateupload = this.$route.query.todate || "";
    this.filename = this.$route.query.filename || "";
    this.fileextension = this.$route.query.fileextension || "";
    this.fromdateupload = this.$route.query.fromdate || "";
    this.todateupload = this.$route.query.todate || "";
    this.fromdatedocument = this.$route.query.fromdatedoc || "";
    this.todatedocument = this.$route.query.todatedoc || "";
    this.documentid = this.$route.query.doc_no_start || "";
    this.documentidto = this.$route.query.doc_no_end || "";
    this.sendername = this.$route.query.sender_name || "";
    this.sellertaxid = this.$route.query.sellerTaxid || "";
    this.sellerbranchid = this.$route.query.sellerBranchid || "";
    this.receivername = this.$route.query.receiver_name || "";
    this.buyertaxid = this.$route.query.receiverTaxid || "";
    this.buyerbranchid = this.$route.query.receiverBranchid || "";
    this.getSourceSystem();
    this.getDataFilters();
    //   console.log(this.$router.app['name'])
  }
};
</script>

<style>
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
#thiscontainer {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  overflow-scrolling: touch;
}
</style>